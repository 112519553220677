import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  const { hasGenericVideo } = props

  return (
    <StyledContainer>
      <USDefaultTemplate
        location={props.location}
        team={props.team}
        lang="en"
        market="us"
        offerCode="us-recu-mk-pro_espn-mo-7d-30pctoff2-v1"
        ctaText="Get Access"
        offerBanner={
          <>
            ✔ Special play for college football{' '}
            <div className="desktop-line-break">fans activated!</div>
            <div style={{ display: 'block' }}>
              Get 30% off 2 months when you{' '}
              <div className="desktop-line-break"> sign up today. </div>
            </div>
          </>
        }
        video={hasGenericVideo ? videos.generic_football.videoUrl : videos.cfb.videoUrl}
        title={'Watch college football all season'}
        tagline="Stream live games on ESPN & more with Fubo"
        networkSet="collegeFootball"
        showRedzone={true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: block;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`
