import React from "react"

const Slope = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 750 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.000157998 200.02L-0.000129153 7.99996C109.694 -24 240.79 60.925 354.141 99.3536C358.082 100.728 362.029 102.117 365.977 103.492C486.533 146.197 605.351 226 749.63 200.02L739.422 198.638L-0.000157998 200.02Z"
        fill="url(#paint0_linear_149_3035)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_149_3035"
          x1="373.06"
          y1="381.238"
          x2="391.214"
          y2="78.0255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9E4FF" />
          <stop offset="1" stop-color="#E8F3FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Slope
