export const updateAnalyticsReady = () => {
  return {
    type: "ANALYTICS_INIT_SUCCESS",
  }
}

export const updateAnalyticsProperties = properties => dispatch => {
  dispatch({
    type: "UPDATE_ANALYTICS_PROPERTIES",
    payload: properties,
  })
  dispatch(updateAnalyticsPropertiesReady())
}

export const updateAnalyticsPropertiesReady = () => {
  return {
    type: "ANALYTICS_PROPERTIES_READY",
  }
}

export const updateParameters = parameters => {
  return {
    type: "UPDATE_PARAMETERS",
    payload: parameters,
  }
}
