import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.espn.videoUrl}
      annotations={videos.espn.annotations}
      title="MIRA DEPORTES AMERICANOS"
      subtitle="Access now for only $24.99 the first month"
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/american/"
    />
  )
}
