import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
// import "slick-carousel/slick/slick-theme.css";
import { defaultCTAURI } from 'src/modules/utils/constants'
import {
  DefaultCtaText,
  DefaultCtaTextShort,
  DefaultNetworkSubtitle,
} from 'src/modules/utils/constants'

const ImageFormatQueryString = '?w=300&h=450&ch=width&auto=format'

class CustomSlide extends Component {
  render() {
    const { index, imageUrl, alt, ...props } = this.props
    const imageUrlWithFormat = `${imageUrl}${ImageFormatQueryString}`

    return <img {...props} src={imageUrlWithFormat} alt={alt} />
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className + ' arrow right'} style={{ ...style }} onClick={onClick}>
      <svg width="11" height="40" viewBox="0 0 24 40">
        <title>icon-caret-right</title>
        <desc>Created with Sketch.</desc>
        <g id="USA-LP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Welcome-v2---US-1600"
            transform="translate(-1542.000000, -3682.000000)"
            fill="rgba(0, 0, 0, 0.7)"
          >
            <g id="Content-Carousel" transform="translate(0.000000, 3102.000000)">
              <g id="Group-14" transform="translate(16.000000, 568.000000)">
                <g
                  id="32px/padded/outlined/icon-chevron-right"
                  transform="translate(1504.000000, 0.000000)"
                >
                  <g
                    id="32px/unpadded/outlined/icon-chevron-right"
                    transform="translate(22.000000, 12.000000)"
                  >
                    <path
                      d="M23.3379295,3.48660549 C24.1589495,2.74768745 24.2255063,1.48310778 23.4865883,0.662087737 C22.7476703,-0.158932307 21.4830906,-0.225489132 20.6620705,0.513428907 L0.662070537,18.5134289 C-0.220690179,19.3079136 -0.220690179,20.6921208 0.662070537,21.4866055 L20.6620705,39.4866055 C21.4830906,40.2255235 22.7476703,40.1589667 23.4865883,39.3379467 C24.2255063,38.5169266 24.1589495,37.2523469 23.3379295,36.5134289 L4.98969423,20.0000172 L23.3379295,3.48660549 Z"
                      id="shape"
                      transform="translate(12.000009, 20.000017) scale(-1, 1) translate(-12.000009, -20.000017) "
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className + ' arrow left'} style={{ ...style }} onClick={onClick}>
      <svg width="11" height="40" viewBox="0 0 24 40">
        <title>icon-caret-left</title>
        <desc>Created with Sketch.</desc>
        <g id="USA-LP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Welcome-v2---US-1600"
            transform="translate(-34.000000, -3682.000000)"
            fill="rgba(0, 0, 0, 0.7)"
          >
            <g id="Content-Carousel" transform="translate(0.000000, 3102.000000)">
              <g id="Group-14" transform="translate(16.000000, 568.000000)">
                <g id="32px/padded/outlined/icon-chevron-left">
                  <g
                    id="32px/unpadded/outlined/icon-chevron-left"
                    transform="translate(18.000000, 12.000000)"
                  >
                    <path
                      d="M23.3379295,3.48660549 C24.1589495,2.74768745 24.2255063,1.48310778 23.4865883,0.662087737 C22.7476703,-0.158932307 21.4830906,-0.225489132 20.6620705,0.513428907 L0.662070537,18.5134289 C-0.220690179,19.3079136 -0.220690179,20.6921208 0.662070537,21.4866055 L20.6620705,39.4866055 C21.4830906,40.2255235 22.7476703,40.1589667 23.4865883,39.3379467 C24.2255063,38.5169266 24.1589495,37.2523469 23.3379295,36.5134289 L4.98969423,20.0000172 L23.3379295,3.48660549 Z"
                      id="shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

const ShowCarousel = ({ ctaQuery, ctaText, headline, imagesArray }) => {
  const [interacted, setInteracted] = useState(false)

  const beforeChangeHandler = () => {
    setInteracted(true)
  }

  const formattedCTAQuery = ctaQuery ? `${defaultCTAURI}?${ctaQuery}` : `${defaultCTAURI}`
  const ctaCopy = ctaText ? ctaText : DefaultCtaTextShort

  const SliderSettings = {
    beforeChange: beforeChangeHandler,
    dots: false,
    speed: 300,
    centerMode: true,
    centerPadding: '175px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    slidesToShow: 9,
    touchThreshold: 20,
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    adaptiveHeight: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          speed: 300,
          centerMode: true,
          centerPadding: '150px',
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          speed: 300,
          centerMode: true,
          centerPadding: '100px',
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          speed: 300,
          centerMode: true,
          centerPadding: '50px',
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 300,
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <ShowCarouselWrapper className={interacted ? 'interacted' : 'notInteracted'}>
      <WiggleAnimation />

      {headline && (
        <HeadlineWrapper>
          <Headline>{headline}</Headline>
        </HeadlineWrapper>
      )}

      <CarouselWrapper>
        <CarouselContain>
          <Slider {...SliderSettings}>
            {imagesArray.map((image, index) => (
              <CustomSlide index={index} imageUrl={image.url} alt={image.alt} />
            ))}
          </Slider>
        </CarouselContain>
      </CarouselWrapper>

      <ButtonWrapper>
        <a href={formattedCTAQuery}>{ctaCopy}</a>
      </ButtonWrapper>
    </ShowCarouselWrapper>
  )
}

export default ShowCarousel

const WiggleAnimation = createGlobalStyle`
@keyframes wiggle {
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-5px);
    }
}
`

const ShowCarouselWrapper = styled.div`
  color: #fff;
  padding: 60px 0 60px 0;
  transition: all 2s;
  background-color: #3e315e;
  background: linear-gradient(-45deg, #3e315e 0%, #1f182b 100%);

  .slick-slider {
    animation: wiggle 7s infinite alternate;
    padding-bottom: 20px;
  }

  &.interacted .slick-slider {
    animation: none;
  }

  .slick-slide {
    // width: 140px;

    &:focus {
      outline: none !important;
    }

    > div {
      // width: 140px;
    }
  }

  .slick-slide img {
    border-radius: 4px;
    box-shadow: 0 7px 4px 0 rgba(18, 18, 18, 0.5);
  }

  .slick-slide:not(.slick-center) img {
    transition: transform 0.6s;
    transform: scale(0.75);
  }

  .arrow {
    width: 45px;
    height: 45px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
    z-index: 10001;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    cursor: pointer;
    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.6);

    &:hover,
    &:focus {
      background-color: #fff;
    }

    &:before {
      display: none;
    }

    @media screen and (max-width: 600px) {
      width: 35px;
      height: 35px;
    }
  }

  .arrow.right {
    right: 7px;
  }
  .arrow.left {
    left: 7px;
  }
`

const HeadlineWrapper = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
`

const Headline = styled.h2`
  font-size: 43px;
  line-height: 1.1;
  font-family: 'Qanelas-Bold', sans-serif;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 736px) {
    font-size: 32px;
  }
`

const CarouselWrapper = styled.div`
  @media only screen and (max-width: 1400px) {
    margin-top: 40px;
  }
`

const CarouselContain = styled.div`
  overflow: hidden;
`

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px auto;
  margin-bottom: 10px;
  font-family: 'Qanelas', sans-serif;
  font-size: 23px;
  text-align: center;

  a {
    display: inline-block;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    background: #fa4616;
    padding: 18px 20px;
    border-radius: 3px;
    font-weight: 700;
    font-family: 'Qanelas-Bold', sans-serif;
    text-align: center;
    width: 320px;

    text-transform: uppercase;

    :hover {
      background: #b33922;
      cursor: pointer;
    }

    @media screen and (max-width: 736px) and (min-width: 320px) {
      font-size: 20px;
      width: 242px;
    }
  }
`
