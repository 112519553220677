import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.liga_mx.videoUrl}
      staticAnnotation={videos.liga_mx.staticAnnotation}
      title="Ve la Liga MX en vivo en TUDN"
      subtitle="Accede ahora por solo $24.99 el primer mes"
      tagline={"ACCEDE DESDE TU SMARTPHONE, TV, COMPUTADORA, O DESDE CUALQUIER OTRO DISPOSITIVO"}
      networkSet="soccerLatino"
      noteLink="/la/liga-mx/"
      // matchtickerSportId="199"
      // matchtickerLeagueName="Liga MX"
      // Liga MX, La Liga, Champions League
      // matchtickerLeagueId={['9208870', '3609994', '3616485']}
    />
  )
}
