import { parseQueryParams } from 'src/modules/utils/parseQueryParams'

export const approvedParams = [
  'ftv_campaign',
  'coupon',
  'headline',
  'pack',
  'offer',
  'irad',
  'irmp',
  'sharedid',
  'promo',
  'addons',
  'addons[]',
  'al',
  'talkable_visitor_uuid',
  'promocode',
  'params',
  'ab_campaign',
  'disableQuarterlyUpsell',
  'lang',
  'app_source',
]

// takes an object of params and filters for only those that are passed into the signup funnel
export const filterCTAParams = params => {
  const filteredParams = {}
  for (const [key, value] of Object.entries(params)) {
    if (approvedParams.includes(key.toLowerCase())) {
      switch (key.toLowerCase()) {
        case 'shareid':
          filteredParams['sharedID'] = value
          break
        case 'talkable_visitor_uuid':
          filteredParams['talkable_visitor_uuid'] = value
          filteredParams['irad'] = 'talkable'
          filteredParams['irmp'] = 'talkable'
          break
        case 'params': {
          const decodedParam = decodeURIComponent(value)
          console.log('decodedParam', decodedParam)
          const parsedDecodedParam = Object.fromEntries(new URLSearchParams(decodedParam))
          Object.entries(parsedDecodedParam).forEach(([param, value]) => {
            if (approvedParams.includes(param.toLowerCase())) {
              filteredParams[param] = value
            }
          })
          break
        }
        default:
          filteredParams[key] = value
          break
      }
    }
  }
  return filteredParams
}
