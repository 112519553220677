import React, { useEffect, useState } from 'react'
// import FallingSnow from 'src/components/common/FallingSnow'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      {/* <FallingSnow /> */}
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        offerBanner="✔ Special offer activated: Save $20 on Fubo"
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Stream Live TV & Sports"
        subtitle="ENJOY BIG SAVINGS THIS SEASON"
        tagline="Watch 350+ top channels including Hallmark Channel, ESPN and more"
        highlightNote="Additional taxes, fees and regional restrictions may apply. $20 savings upon first billing period."
        showHoliday={true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-note {
    font-size: 0.8rem;
  }
`
