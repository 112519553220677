import React from 'react'

export const newPlanData = (channels, planLength, noEnt) => {
  // const entertainmentChannels = channels?.['entertainment'] || 64
  // const soccerChannels = channels?.['soccer'] || 73
  // const premiumChannels = channels?.['premium'] || 94
  const entertainmentChannels = 64
  const soccerChannels = 79
  const premiumChannels = 101

  const planData = {
    entertainment: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_entertainment-2.jpg?w=451&ch=width&auto=format,compress',
      plan: 'entertainment',
      title: 'Entertainment',
      subtitle: 'Best for Families',
      price: 'C$16.99/mo',
      listItems: [
        { available: true, text: `${entertainmentChannels} channels - 1000's of popular titles` },
        { available: false, text: 'Premier League matches' },
        { available: true, text: 'Lifestyle channels - HGTV & more' },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Cloud DVR - Up to 1000 hours of space' },
      ],
      footnote: '',
      name: 'Entertainment',
      selectionHeader: 'Choose monthly or quarterly billing',
      selectionSubheader: '1000 hours of Cloud DVR when choosing a quarterly plan.',
      offerCode: {
        monthly: 'canada-month-entertainment-v2-5d',
        quarterly: 'canada-quarter-entertainment-v2-5d',
      },
      pricePerMonth: {
        monthly: 'C$16.99/mo',
        quarterly: 'C$15.99/mo',
      },
      totalPrice: {
        monthly: 'C$16.99/mo',
        quarterly: 'C$47.97',
      },
      savings: {
        monthly: '250 hours of DVR',
        quarterly: '1000 hours of DVR',
      },
      cardFootnote: {
        quarterly: '*Prepaid for 3 months at C$47.97',
      },
      ctaText: 'Get Access',
      // ctaText: 'Start For Free',
    },
    soccer: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_soccer-2.jpg?w=451&ch=width&auto=format,compress',
      plan: 'soccer',
      title: 'Sports',
      subtitle: 'Best for Soccer and Sports Fans',
      price: 'Starting at C$23.33/mo',
      listItems: [
        { available: true, text: `${soccerChannels} channels - Soccer and sports` },
        { available: true, text: 'All Premier League matches' },
        { id: '4k', available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Serie A, Coppa Italia, Ligue 1 & more' },
        { available: true, text: 'Cloud DVR - 250 hours of space' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),
      name: 'Sports',
      selectionHeader: 'Choose monthly, quarterly or annual billing',
      selectionSubheader: 'Save up to 22% when choosing an annual plan.',
      offerCode: {
        monthly: 'ca-recu-ga-sports-mo-nt-v1',
        quarterly: 'ca-recu-ga-sports-qt-nt-20amtoff1-v1',
        annual: 'ca-recu-ga-sports-yr-nt-55amtoff1-v1',
        // quarterly: 'canada-quarter-essentials-v4',
        // annual: 'canada-year-essentials-v5',
      },
      pricePerMonth: {
        monthly: 'C$29.99/mo',
        quarterly: 'C$26.66/mo',
        annual: 'C$23.33/mo',
      },
      // Promo pricing
      discountedPricePerMonth: {
        monthly: '',
        quarterly: 'C$19.99/mo',
        annual: 'C$18.75/mo',
      },
      savingsNote: {
        monthly: '',
        quarterly: 'Save $30 over 3 months*',
        annual: 'Save $134 over 12 months*',
      },
      totalPrice: {
        monthly: 'C$29.99',
        quarterly: 'C$79.97',
        annual: 'C$279.99',
      },
      // Promo pricing
      discountedTotalPrice: {
        monthly: '',
        quarterly: 'C$59.97',
        annual: 'C$224.97',
      },
      savings: {
        monthly: '',
        quarterly: 'Save 11%',
        annual: 'Save 22%',
      },
      cardFootnote: {
        monthly: '',
        quarterly: (
          <>
            *Discount based on 3 months of service of Sports Monthly plan. Prepaid for 3 months at
            C$59.97. Renews at full price of C$79.97.
          </>
        ),
        annual: (
          <>
            *Discount based on 12 months of service of Sports Monthly plan. Prepaid for 12 months at
            C$224.97. Renews at full price of C$279.99.
          </>
        ),
      },
      ctaText: 'Get Access',
      // ctaText: 'Start For Free',
    },
    premium: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_premium-2.jpg?w=451&ch=width&auto=format,compress',
      plan: 'premium',
      title: 'Premium',
      subtitle: 'Best for Everyone',
      price: 'Starting at C$32.99/mo',
      listItems: [
        { available: true, text: `${premiumChannels} channels - Entertainment and sports` },
        { available: true, text: 'All Premier League & Serie A matches' },
        { available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Includes NBA TV' },
        { available: true, text: 'Lifestyle channels - HGTV & more' },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Cloud DVR - 1000 hours of space' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),
      name: 'Premium',
      selectionHeader: 'Choose monthly, quarterly or annual billing',
      selectionSubheader: 'Save up to 23% when choosing an annual plan.',
      offerCode: {
        monthly: 'ca-recu-ga-premium-mo-nt-v1',
        quarterly: 'ca-recu-ga-premium-qt-nt-v1',
        annual: 'ca-recu-ga-premium-yr-nt-v1',
      },
      pricePerMonth: {
        monthly: 'C$42.99/mo',
        quarterly: 'C$34.99/mo',
        annual: 'C$32.99/mo',
      },
      // Promo pricing
      discountedPricePerMonth: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      // Promo pricing
      savingsNote: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      totalPrice: {
        monthly: 'C$42.99',
        quarterly: 'C$104.98',
        annual: 'C$395.88',
      },
      // Promo pricing
      discountedTotalPrice: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      savings: {
        monthly: '',
        quarterly: 'Save 18%',
        annual: 'Save 23%',
      },
      cardFootnote: {
        monthly: '',
        quarterly: <>*Prepaid for 3 months at C$104.98.</>,
        annual: <>*Prepaid for 12 months at C$395.88.</>,
      },
      ctaText: 'Get Access',
      // ctaText: 'Start For Free',
    },
  }

  return planData
}

export const newTabData = {
  monthly: {
    tabNote: '',
  },
  quarterly: {
    tabNote: '',
    // tabNote: 'Save up to 13%',
  },
  annual: {
    tabNote: '',
    // tabNote: 'Save up to 26%',
  },
}
