// Callsigns with LPs
const activeNetworks = {
  ATTPTHD: 'AT&T SportsNet Pittsburgh',
  ATTSWHD: 'Space City Home Network',
  BEIN1HD: 'beIN Sports',
  BETHD: 'BET',
  BTN: 'Big Ten Network',
  BVO: 'Bravo',
  CBS: 'CBS',
  CBSSN: 'CBS Sports Network',
  CNBC: 'CNBC',
  CCHD: 'Comedy Central',
  DEP: 'FOX Deportes',
  EE: 'E!',
  FNCHD: 'Fox News',
  FOX: 'FOX',
  FS1: 'FS1',
  FS2: 'FS2',
  GLFC: 'Golf Channel',
  MARQ: 'Marquee Sports Network',
  MASNHD: 'MASN',
  MLBHD: 'MLB Network',
  MSG: 'MSG',
  MSNBC: 'MSNBC',
  MTVHD: 'MTV',
  NBC: 'NBC',
  NESN: 'NESN',
  NFLHD: 'NFL Network',
  NHLNET: 'NHL Network™',
  NIKHD: 'Nickelodeon',
  RAIITA: 'RAI Italia',
  SNY: 'SNY',
  SYFY: 'Syfy',
  TENISHD: 'Tennis Channel',
  USAN: 'USA Network',
  VH1HD: 'VH1',
  WEATHHD: 'The Weather Channel',
  PRLITVGD: 'Premier League TV',
  YESHDNY: 'YES Network',
}

// Callsigns without LPs, but mights be used by affiliates
const extraNetworks = {
  AMC: 'AMC Network',
  BABYUS: 'Baby TV',
  BBC: 'BBC',
  BBCWN: 'BBC World News',
  BEINCHD: 'beIN Sports Canada',
  BTN2: 'Big Ten Network 2',
  CMTVHD: 'CMT',
  CNNHD: 'CNN',
  // DSCHD: 'Discovery Channel',
  FNESPRI: 'FOX Network East',
  FBNHD: 'FOX Business',
  FOXRSN: 'FOX Sports Network',
  IMGUCI: 'fubo Cycling',
  FXE: 'FX',
  FXM: 'FX Movie Channel',
  IFC: 'IFC',
  HALLHD: 'Hallmark Channel',
  HMMHD: 'Hallmark Movies & Mysteries',
  LOGOHD: 'Logo',
  MSG2: 'MSG2',
  NBCSN: 'NBC Sports Network',
  NICJRHD: 'Nick Jr.',
  OXG: 'Oxygen',
  PARHD: 'Paramount',
  TENISHS: 'Tennis Channel',
  TVLNDHD: 'TV Land',
  SPR: 'Universal Kids',
  VSIN: 'VSiN',
}

export const networkNames = {
  ...activeNetworks,
  ...extraNetworks,
}
