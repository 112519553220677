import React from 'react'
import { useSelector } from 'react-redux'
import CompareCardContainer from 'src/components/template/compare/components/modules/CompareCardContainer'
import CompareCardNetworks from 'src/components/template/compare/components/modules/CompareCardNetworks'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import Footnote from 'src/components/template/compare/components/modules/Footnote'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { selectPostalCode } from 'src/modules/reducers/market'
import styled from 'styled-components'

const CompareChannelsSection = ({ competitor, data }) => {
  const { heading, subheading, networksData } = data

  const postalCode = useSelector(selectPostalCode)

  const channelfinderUrl = postalCode
    ? `https://www.fubo.tv/channelfinder?postal=${postalCode}`
    : 'https://www.fubo.tv/channelfinder'

  return (
    <StyledCompareChannelsSection>
      <CompareContainer className="content">
        <SectionText heading={heading} subheading={subheading} />
        <CompareCardContainer className="cards-section">
          <CompareCardNetworks networks={networksData} />
          <CompareCardNetworks networks={networksData} competitor={competitor} />
        </CompareCardContainer>
        <Footnote>* Regional restrictions apply. Prices may vary with taxes and fees.</Footnote>
        <div className="link">
          <a href={channelfinderUrl}>Search all Fubo plans and channels available in your area.</a>
        </div>
      </CompareContainer>
    </StyledCompareChannelsSection>
  )
}

export default CompareChannelsSection

const StyledCompareChannelsSection = styled.section`
  margin-bottom: 40px;
  .link {
    text-align: center;
    margin-top: 20px;
    display: none;

    ${breakpointQuery.desktop} {
      display: block;
    }
    a {
      font-family: 'Qanelas';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height, or 130% */

      text-decoration-line: underline;

      /* Solids/Blue (Link Text Color) */

      color: #1c9fd0;

      text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);
    }
  }
`
