import React, { useEffect, useState } from 'react'
import { newPlanData, newTabData } from 'src/constants/caPlanData'
import { useSelector } from 'react-redux'
import { track } from 'src/modules/analytics/track'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import PlanCardWithImage from 'src/components/common/PlanCardWithImage'
import styled from 'styled-components'
import {
  selectCanadianChannels,
  selectCanadianEntertainmentChannels,
  selectCanadianEssentialsChannels,
} from 'src/modules/reducers/plans/selectors'
import TabSelector from 'src/components/common/TabSelector'

const BundlePlansCAByPlan = ({
  title = 'Which plan is right for you?',
  subtitle = 'Canada’s home to streaming live soccer and top entertainment',
  plan,
  offerCode,
  soccerPlanCardImage,
  premiumPlanCardImage,
  ctaText,
  forcePlanLength,
  planCardOverride,
}) => {
  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const canadianEntertainmentChannels = useSelector(selectCanadianEntertainmentChannels)
  const canadianSoccerChannels = useSelector(selectCanadianEssentialsChannels)
  const canadianPremiumChannels = useSelector(selectCanadianChannels)

  const channels = {
    entertainment: canadianEntertainmentChannels.length,
    soccer: canadianSoccerChannels.length,
    premium: canadianPremiumChannels.length,
  }

  const dict = {
    Entertainment: 'entertainment',
    Sports: 'soccer',
    Premium: 'premium',
  }

  const handleTabClick = selected => {
    setActiveTab(selected)
    const eventProperties = {
      ...analyticsProperties,
      ui_data: {
        category: 'clicked',
        event_data: {
          component: 'cta_plan_length_tab',
          content_name: selected,
        },
      },
    }
    console.log('eventProperties', eventProperties)
    track('lp_interaction', eventProperties)
  }

  const tabs = ['Sports', 'Premium']

  const [activeTab, setActiveTab] = React.useState(tabs[0])
  const [planData, setPlanData] = React.useState(newPlanData())

  useEffect(() => {
    let data = { ...newPlanData(channels) }

    if (soccerPlanCardImage) {
      data.soccer.promoImg = soccerPlanCardImage
    }

    if (premiumPlanCardImage) {
      data.premium.promoImg = premiumPlanCardImage
    }

    setPlanData(data)
    console.log('planData', data)
  }, [activeTab, soccerPlanCardImage, premiumPlanCardImage])

  const planLengths = ['Monthly', 'Quarterly', 'Annual']

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      {!forcePlanLength && (
        <div className="section-tabs">
          <TabSelector tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
        </div>
      )}
      <div className="section-content">
        {planLengths.map((length, index) => {
          const card = planData[dict[activeTab]]

          let cardListItems = card.listItems.map(item => ({ ...item }))

          const lengthLowercase = length.toLowerCase()
          if (lengthLowercase === 'monthly' && activeTab === 'Sports') {
            cardListItems.find(item => item.id === '4k').available = false
            cardListItems.find(item => item.id === '4k').text = 'Premier League in 4K'
          }
          if (activeTab === 'Entertainment' && lengthLowercase === 'annual') {
            return null
          } else {
            return (
              <PlanCardWithImage
                key={index}
                promoImg={card.promoImg}
                plan={card.plan}
                length={length}
                title={card.title}
                subtitle={card.subtitle}
                price={card.pricePerMonth?.[lengthLowercase]}
                discountedPrice={card.discountedPricePerMonth?.[lengthLowercase]}
                savingsNote={card.savingsNote?.[lengthLowercase]}
                listItems={cardListItems}
                offerCode={card.offerCode[lengthLowercase]}
                footnote={card.cardFootnote?.[lengthLowercase] || null}
                ctaText={ctaText || card.ctaText}
                banner={activeTab === 'Sports' && lengthLowercase === 'annual' && 'BEST VALUE!'}
              />
            )
          }
        })}
      </div>
    </StyledBundleSection>
  )
}

export default BundlePlansCAByPlan

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 90px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-family: Qanelas-Medium;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 600px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin: 0 auto 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-family: 'Qanelas-Medium', sans-serif;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
    }
  }

  .planCard .planCard__content .planCard__header .discount__note {
    font-size: 1rem;
  }

  .planCard .planCard__content .planCard__header .faded-price,
  .planCard .planCard__content .planCard__header .asterisk {
    display: none;
  }
`
