import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_suns.videoUrl}
    staticAnnotation={videos.bally_suns.staticAnnotation}
    title="Watch Phoenix basketball live"
    subtitle="STREAM THE SUNS ON BALLY SPORTS ARIZONA"
    customNetworks={['ballysportsarizona']}
  />
)
