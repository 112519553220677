import React from 'react'
import CompareCardList from 'src/components/template/compare/components/modules/CompareCardList'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const CompleteChannelComparison = ({ competitor, data }) => {
  const { heading, subheading, content } = data
  return (
    <StyledCompleteChannelComparison>
      <CompareContainer className="content">
        <SectionText heading={heading} subheading={subheading} className="section-text">
          {content}
        </SectionText>
        <CompareCardList
          list={[
            'Starting at 185+ live channels',
            'Unlimited Cloud DVR Space',
            'Up to 10 simultaneous streams',
            'Monthly price: $79.99-$99.99*',
          ]}
          className="card-cta"
          ctaPosition="bottom"
        />
      </CompareContainer>
    </StyledCompleteChannelComparison>
  )
}

export default CompleteChannelComparison

const StyledCompleteChannelComparison = styled.section`
  .section-text {
    margin-bottom: 20px;
  }

  .card-cta {
    align-items: flex-start;
    background-image: linear-gradient(88.71deg, #0c071e 22.17%, rgba(12, 7, 30, 0) 100.62%),
      linear-gradient(rgba(0, 15, 38, 0.4), rgba(0, 15, 38, 0.4)),
      url('https://assets-imgx.fubo.tv/marketing/lp/images/compare/compare_hero_2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 32px 40px;
    border: 1px solid #003a94;

    ${breakpointQuery.desktop} {
      background-image: linear-gradient(91.24deg, #0c071e 20.33%, rgba(12, 7, 30, 0) 66.3%),
        linear-gradient(rgba(0, 15, 38, 0.4), rgba(0, 15, 38, 0.4)),
        url('https://assets-imgx.fubo.tv/marketing/lp/images/compare/compare_hero_2.jpg');
    }
    .logo {
      justify-content: flex-start;
    }
  }
`
