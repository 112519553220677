import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Penguins_AT&T_LP_2021_15s.mp4'}
      title="Watch live Pittsburgh Penguins hockey"
      subtitle="PLUS GET PITTSBURGH SPORTS TALK"
      tagline="Stream AT&T SportsNet anytime, from any device. Regional restrictions apply."
      networkSet="hockey"
      customNetworks={['attsportsnet']}
    />
  )
}
