import { rgbValues } from '@fubotv/design-system/lib/modules/m/theme/palette'

// create the palette with the correct rgb values as a string for ease of use
const setColors = () => {
  const palette: Record<string, any> = {}
  Object.keys(rgbValues).forEach(key => {
    palette[key] = `rgb(${rgbValues[key]})`
  })
  return palette
}

const palette = setColors()

// export both rgbValues and the created palette in case we will need any rgba values
export { rgbValues, palette }
