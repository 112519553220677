import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.generic_soccer.videoUrl}
      title="Ve El Clásico online"
      subtitle="REAL MADRID VS BARCELONA EN VIVO EN ESPAÑOL"
      tagline="Accede al partido y a mucho más desde tu celular, TV y otros dispositivos"
      networkSet="soccerLatino"
      noteLink="/la/clasico/"
    />
  )
}
