import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import CTAButton from 'src/components/common/CTAButtonNew'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { createUrl } from 'src/modules/utils/createUrl'
import LazyImage from '../LazyLoad/LazyImage'

const PlatformSection = ({ ctaText, ctaHrefOverride, lang = 'en', onClick, hasOfferInput }) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  const [isMobile, setIsMobile] = useState(false)

  const checkIsMobile = () => {
    return window.innerWidth < breakpoint
  }

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(checkIsMobile())
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const logosUrlPrefix = 'https://assets-imgx.fubo.tv/marketing/lp/images/platforms/2024/'
  const logosUrlSuffix = '?ch=width&auto=format,compress'

  const platformLogosDesktop = [
    {
      src: 'roku.png',
      alt: 'Roku',
    },
    {
      src: 'firetv.png',
      alt: 'Fire TV',
    },
    {
      src: 'lg.png',
      alt: 'LG',
    },
    {
      src: 'vizio.png',
      alt: 'Vizio',
    },
    {
      src: 'appletv.png',
      alt: 'Apple TV',
    },
    {
      src: 'hisense.png',
      alt: 'Hisense',
    },
    {
      src: 'android.png',
      alt: 'Android',
    },
    {
      src: 'ios.png',
      alt: 'iOS',
    },
    {
      src: 'xbox.png',
      alt: 'Xbox',
    },
    {
      src: 'chromecast.png',
      alt: 'Chromecast',
    },
  ]

  const platformLogosMobile = [
    {
      src: 'roku.png',
      alt: 'Roku',
    },
    {
      src: 'firetv.png',
      alt: 'Fire TV',
    },
    {
      src: 'lg.png',
      alt: 'LG',
    },
    {
      src: 'vizio.png',
      alt: 'Vizio',
    },
    {
      src: 'appletv.png',
      alt: 'Apple TV',
    },
    {
      src: 'hisense.png',
      alt: 'Hisense',
    },
    {
      src: 'android.png',
      alt: 'Android',
    },
    {
      src: 'ios.png',
      alt: 'iOS',
    },
    {
      alt: '+ More',
    },
    {
      display: false,
    },
  ]

  const [platformLogos, setPlatformLogos] = useState(platformLogosDesktop)

  useEffect(() => {
    if (isMobile) {
      setPlatformLogos(platformLogosMobile)
    } else {
      setPlatformLogos(platformLogosDesktop)
    }
  }, [isMobile])

  // GSAP animation
  gsap.registerPlugin(useGSAP, ScrollTrigger)

  const gsapContainer = useRef()

  useGSAP(
    () => {
      let mm = gsap.matchMedia()

      mm.add(`(min-width: ${desktopBreak})`, () => {
        gsap.from('.platform-image', {
          opacity: 0,
          y: 200,
          scrollTrigger: {
            trigger: '.platform-content',
            start: 'top 60%',
            end: 'top 5%',
            scrub: true,
            // markers: true,
          },
        })

        const platforms = gsap.utils.toArray('.platform-logo-item')
        gsap.from(platforms, {
          opacity: 0,
          y: 100,
          duration: 1,
          stagger: {
            each: 0.1,
            from: 'start',
          },
          scrollTrigger: {
            trigger: '.platform-logos',
            start: 'top 100%',
            end: 'top 20%',
            scrub: true,
            // markers: true,
          },
        })
        return () => {
          // custom cleanup code here (runs when it STOPS matching)
        }
      })

      mm.add(`(max-width: ${mobileBreak})`, () => {
        gsap.from('.platform-image', {
          opacity: 0,
          y: 150,
          scrollTrigger: {
            trigger: '.platform-content',
            start: 'top 90%',
            end: 'top 30%',
            scrub: true,
            // markers: true,
          },
        })

        const platforms = gsap.utils.toArray('.platform-logo-item')
        console.log(platforms)
        gsap.from(platforms, {
          opacity: 0,
          y: 100,
          duration: 1,
          stagger: {
            each: 0.1,
            from: 'start',
          },
          scrollTrigger: {
            trigger: '.platform-logos',
            start: 'top 100%',
            end: 'top 20%',
            scrub: true,
            // markers: true,
          },
        })
        return () => {
          // custom cleanup code here (runs when it STOPS matching)
        }
      })
    },
    { dependency: [platformLogos], scope: gsapContainer, revertOnUpdate: true }
  ) // <-- scope is for selector text

  return (
    <StyledPlatformSection ref={gsapContainer}>
      <div className="platform-header">
        <h2>Stream live and on demand from anywhere</h2>
      </div>
      <div className="platform-content">
        <div className="platform-image">
          <LazyImage
            src="https://assets-imgx.fubo.tv/marketing/lp/images/devices/devices-2024.png?ch=width&auto=format,compress"
            alt=""
          />
        </div>
        <div className="platform-logos">
          {platformLogos.map((logo, index) => {
            const url = logosUrlPrefix + logo.src + logosUrlSuffix
            const alt = logo.alt
            return logo.src ? (
              <div className="platform-logo-item" key={index}>
                <LazyImage src={url} alt={alt} />
              </div>
            ) : logo.alt ? (
              <div className="platform-logo-item" key={index}>
                <span className="platform-logo-text">{alt}</span>
              </div>
            ) : (
              <div className="platform-logo-item" style={{ display: 'none' }} key={index}></div>
            )
          })}
        </div>
      </div>
      <div className="cta-container">
        <CTAButton
          link={hasOfferInput ? '#' : ctaHrefOverride || ctaURL}
          // onClick={onClick || handleOnClick}
        >
          {/* {ctaText || copy.ctaText} */}
          Start for free
        </CTAButton>
      </div>
    </StyledPlatformSection>
  )
}

export default PlatformSection

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

// 3:2 Figma to Mobile (calculate 2/3 of figma sizing for mobile)

const StyledPlatformSection = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(202.38% 94.78% at 50% 5.22%, #2c323e 0%, #14171f 89.31%);
  overflow: hidden;

  .platform-header {
    margin: 40px 40px;
    text-align: center;
    font-family: 'Qanelas', sans-serif;

    @media (min-width: ${desktopBreak}) {
      margin: 86px 20px 65px;
    }

    h2 {
      color: #fff;
      text-align: center;
      font-family: Qanelas;
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 1.1;

      @media (min-width: ${desktopBreak}) {
        font-size: 38px;
        max-width: none;
      }
    }
  }
  .platform-content {
    margin-bottom: 40px;
    max-width: 100%;
    @media (min-width: ${desktopBreak}) {
      margin-bottom: 65px;
    }
    .platform-image {
      margin: 0 auto 30px;
      max-width: 720px;
      @media (max-width: ${mobileBreak}) {
        padding: 0 20px;
        max-width: 600px;
      }
      img {
        width: 100%;
      }
    }
    .platform-logos {
      max-width: 400px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 34px 37px;
      padding: 0 40px;
      margin: 0 auto;
      @media (min-width: ${desktopBreak}) {
        max-width: 960px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 50px 90px;
        padding: 0 60px;
      }
      .platform-logo-item {
        ${'' /* width: 120px;
        height: 46px; */}
        aspect-ratio: 120 / 46;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: ${desktopBreak}) {
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .platform-logo-text {
          color: var(--Solids-White, #fff);
          text-align: center;
          font-family: Qanelas;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.16;
          ${'' /* letter-spacing: 0.02em; */}
        }
      }
    }
  }
  .platform-item {
    font-family: Qanelas;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 20px;

    background: linear-gradient(147deg, #fa8b6e 3.43%, #ff3a03 85.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: ${desktopBreak}) {
      font-size: 72px;
    }
  }
  .cta-container {
    margin-bottom: 56px;
    @media (min-width: ${desktopBreak}) {
      margin-bottom: 84px;
    }
    .CTAButton {
      background: transparent;
      border: 2px solid #fff;
    }
  }
`
