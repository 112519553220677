import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { defaultCTAURI, LatinoESDefaultCtaText } from 'src/modules/utils/constants'
import {
  DefaultCtaText,
  DefaultCtaTextShort,
  DefaultNetworkSubtitle,
} from 'src/modules/utils/constants'

import { track } from 'src/modules/analytics/track'
import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import CTAButton from 'src/components/common/CTAButton'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'

const NewTrialReminder = ({
  ctaText,
  image,
  title,
  subtitle,
  miceText,
  background,
  lang = 'en',
  ctaHrefOverride,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  const isEs = lang === 'es'
  const ctaCopy = ctaText ? ctaText : isEs ? LatinoESDefaultCtaText : DefaultCtaText

  const handleOnClick = e => {
    const eventProperties = {
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'cta_free_trial_section',
        content_name: ctaCopy,
      },
    }
    track('lp_interaction', eventProperties)
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'feature')

  return (
    <SectionWrapper background={background}>
      <SectionInner>
        <ImageWrapper>
          {image ? (
            <img src={image} alt="" />
          ) : (
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/devices/LP_group_fubo-devices-min-v2.png?ch=width&auto=format,compress"
              width="100%"
              alt=""
            />
          )}
        </ImageWrapper>
        <SectionContent>
          <SectionTitle>
            {title || (isEs ? 'Lo Mejor de la TV en vivo' : 'Enjoy Your Free Trial')}
          </SectionTitle>
          <SectionParagraph>
            {subtitle ||
              (isEs
                ? 'Deportes, entretenimiento y mucho más!'
                : "Get to know everything we offer with a free trial. We'll email you when it's almost over.")}
          </SectionParagraph>
          <SectionCTA>
            <ButtonWrapper>
              <CTAButton link={ctaHrefOverride || ctaURL} onClick={handleOnClick}>
                {ctaCopy}
              </CTAButton>
            </ButtonWrapper>
          </SectionCTA>
        </SectionContent>
      </SectionInner>
      {miceText && <div className="mice-text">{miceText}</div>}
    </SectionWrapper>
  )
}

export default NewTrialReminder

const SectionWrapper = styled.section`
  width: 100%;
  padding: 90px 0;
  background-color: #3e315e;
  background: ${props => props.background || 'linear-gradient(-45deg, #3e315e 0%, #1f182b 100%)'};
  font-family: 'Qanelas', sans-serif;

  @media (max-width: 981px) {
    padding: 30px 0;
  }

  .mice-text {
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    opacity: 0.7;
    max-width: 800px;
    margin: 0 auto;
    @media (min-width: 982px) {
      margin-top: 50px;
    }
  }
`

const SectionInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  gap: 0 75px;

  @media (max-width: 981px) {
    flex-direction: column;
    padding: 10px 0 20px 0;
  }
`

const ImageWrapper = styled.div`
  width: 635px;
  ${'' /* margin-right: 75px; */}

  @media (min-width: 982px) {
    flex: 1 1 50%;
  }

  @media (max-width: 981px) {
    margin: 10px auto 30px auto;
    max-width: 75%;
  }

  img {
    width: 100%;
  }
`

const SectionContent = styled.div`
  max-width: 550px;
  color: #fff;

  @media (min-width: 982px) {
    flex: 1 1 50%;
  }

  @media (max-width: 981px) {
    text-align: center;
    width: 80%;
  }
`

const SectionTitle = styled.h2`
  font-size: 43px;
  line-height: 1.1;
  font-family: 'Qanelas-Bold', sans-serif;
  font-weight: bold;
  width: 100%;

  @media (max-width: 981px) {
    padding-bottom: 0;
    text-align: center;
  }

  @media (max-width: 736px) {
    font-size: 32px;
  }
`
const SectionParagraph = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 30px;
  font-family: Arial, sans-serif;
  max-width: 460px;
`

const SectionCTA = styled.div``

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px auto;
`
