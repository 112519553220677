import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.concacaf_gold_cup.videoUrl}
    title="Watch the CONCACAF Gold Cup"
    subtitle="WITH EVERY GAME LIVE ON FOX, FS1 & FS2"
    tagline="Stream them from any device with Fubo"
    networkSet="soccer"
    customNetworks={['fox', 'fs1', 'fs2', 'tudn']}
  />
)
