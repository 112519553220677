import React from "react"
import BallySportsMLB from "src/components/page/bally-sports-mlb"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_braves.videoUrl}
    staticAnnotation={videos.bally_braves.staticAnnotation}
    title="Watch Atlanta baseball live"
    subtitle="STREAM THE BRAVES ON BALLY SPORTS SOUTH & SOUTHEAST"
    customNetworks={["ballysportssouth", "ballysportssoutheast"]}
  />
)