import React from "react"
import BallySportsMLB from "src/components/page/bally-sports-mlb"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_tigers.videoUrl}
    staticAnnotation={videos.bally_tigers.staticAnnotation}
    title="Watch Detroit baseball live"
    subtitle="STREAM THE TIGERS ON BALLY SPORTS DETROIT"
    customNetworks={["ballysportsdetroit"]}
  />
)