import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default props => (
  <StyledContainer>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/wnba.jpg?w=1920&ch=width&auto=format,compress"
      title="Watch WNBA with Fubo"
      subtitle="CATCH THE ACTION LIVE ALL SEASON LONG"
      tagline="Stream games from your TV, computer & other devices"
      networkSet="womensBasketball"
      league="WNBA"
      showRSN={true}
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .videoWrapper {
    @media (max-width: 768px) {
      background-position: 60% center;
    }
  }
`
