import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      title="Watch MLB live with Fubo"
      subtitle="CATCH LIVE GAMES ALL SEASON LONG"
      tagline="Stream them from your TV, phone and other devices"
      networkSet="baseball"
      league="mlb"
      showRSN={true}
      video={videos.mlbtv.videoUrl}
      matchtickerSportId="58"
      matchtickerLeagueName="MLB"
      matchtickerLeagueId="191273"
      highlightImage="https://assets-imgx.fubo.tv/marketing/lp/images/devices/mlb-tv-phone.png?ch=width&auto=format,compress"
      highlightHeadline="Get MLB.TV with Fubo"
      highlightSubtitle="See every out-of-market MLB team in action in over 2,000 live games this season. Watch them all live from your TV, phone & other devices."
      highlightBackground="radial-gradient(98.95% 98.95% at 50% 1.05%, #333F51 0%, #0D1B31 92%)"
      caRedirectUrl="/ca/mlb-tv-ca/"
    />
  )
}
