import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Rockets_AT&T_2021_LP_15s.mp4'}
      title="Watch live Rockets basketball"
      subtitle="PLUS GET HOUSTON SPORTS TALK AND MORE"
      tagline="Stream Space City Home Network from your TV and other devices. Regional restrictions apply."
      networkSet="basketball"
      customNetworks={['spacecity']}
    />
  )
}
