import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="es"
    market="us"
    video={videos.soccer.videoUrl}
    annotations={videos.soccer.annotations}
    title="Ve futbol en vivo"
    subtitle="ACCEDE DESDE TU CELULAR, TV Y MÁS"
    tagline="Sigue las ligas de fútbol más populares de todo el mundo"
    networkSet="soccer"
    noteLink="/soccer/"
  />
)
