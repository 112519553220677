import React, { useEffect, useState } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

import { emitter } from '@marvelapp/react-ab-test'
import { useABTest } from 'src/modules/hooks/useABTest'
import { networkLogos } from 'src/constants/networkLogos'

const abName = 'NFL_Video_v_Static'
let definedVariants = [
  abName + '-video-talking-heads',
  abName + '-wide-field',
  abName + '-football-catch',
  abName + '-football-talent',
]

emitter.defineVariants(abName, definedVariants)
emitter.calculateActiveVariant(abName)
let variants = {
  [definedVariants[0]]: definedVariants[0],
  [definedVariants[1]]: definedVariants[1],
  [definedVariants[2]]: definedVariants[2],
  [definedVariants[3]]: definedVariants[3],
}

export default props => {
  const [testVariant, setTestVariant] = useState(null)
  let teamTitle = 'Watch football and more'
  let pageTitle = 'Watch football and more'

  if (props.teamShort) {
    teamTitle = `Watch ${props.teamShort} football coverage live`
  }

  if (props.team) {
    pageTitle = `Watch ${props.team} football coverage live`
  }

  const variant = useABTest(abName, variants)
  useEffect(() => {
    if (typeof variant !== 'undefined' && variant !== null) {
      setTestVariant(variant)
    }
  }, [variant])

  if (testVariant == definedVariants[1]) {
    //-wide-field
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title={teamTitle}
        pageTitle={pageTitle}
        subtitle="2024-2025 SEASON IS UNDERWAY"
        tagline="Stream CBS, ESPN, FOX and 200+ more live channels"
        networkSet="nfl"
        noVideo
        staticAnnotation={null}
        annotations={null}
        // teams
        team={props.team}
        removeCable={true}
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-HORZ-V1.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-VERT-V1.jpg?ch=width&auto=format,compress'
        }
        matchtickerSportId="111"
        matchtickerLeagueName="NFL"
        matchtickerLeagueId="191277"
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[2]) {
    //-football-catch
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title={teamTitle}
        pageTitle={pageTitle}
        subtitle="2024-2025 SEASON IS UNDERWAY"
        tagline="Stream CBS, ESPN, FOX and 200+ more live channels"
        networkSet="nfl"
        // teams
        team={props.team}
        removeCable={true}
        noVideo
        staticAnnotation={null}
        annotations={null}
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-HORZ-V2.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-VERT-V2.jpg?ch=width&auto=format,compress'
        }
        matchtickerSportId="111"
        matchtickerLeagueName="NFL"
        matchtickerLeagueId="191277"
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[3]) {
    //-football-talent
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title={teamTitle}
        pageTitle={pageTitle}
        subtitle="2024-2025 SEASON IS UNDERWAY"
        tagline="Stream CBS, ESPN, FOX and 200+ more live channels"
        networkSet="nfl"
        // teams
        team={props.team}
        removeCable={true}
        staticAnnotation={networkLogos.fox}
        noVideo
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-TALENT-STATIC-HORZ.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-TALENT-STATIC-VERT.jpg?ch=width&auto=format,compress'
        }
        matchtickerSportId="111"
        matchtickerLeagueName="NFL"
        matchtickerLeagueId="191277"
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[0]) {
    // CONTROL
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.nfl.videoUrl}
        annotations={videos.nfl.annotations}
        title={teamTitle}
        pageTitle={pageTitle}
        subtitle="2024-2025 SEASON IS UNDERWAY"
        tagline="Stream CBS, ESPN, FOX and 200+ more live channels"
        networkSet="nfl"
        // teams
        team={props.team}
        // Remove cable references
        removeCable={true}
        matchtickerSportId="111"
        matchtickerLeagueName="NFL"
        matchtickerLeagueId="191277"
        showRedzone={true}
      />
    )
  } else {
    return null
  }
}
