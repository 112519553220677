import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      video={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/SeattleKraken2022_1280x720_LP_15s.mp4'
      }
      staticAnnotation={networkLogos.rootsports}
      title="Watch Seattle Kraken hockey"
      subtitle="SEE YOUR KRAKEN IN ACTION HOME OR AWAY"
      tagline="Watch them & other Seattle teams live from any device. Regional restrictions apply."
      networkSet="hockey"
      customNetworks={['rootsports']}
    />
  )
}
