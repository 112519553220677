import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/kentucky-derby.mp4'}
    title="Watch the Kentucky Derby live on NBC"
    subtitle="COVERAGE STARTS AT 2:30 PM ET"
    tagline="STREAM LIVE & ON-DEMAND"
    customNetworks={['nbc']}
  />
)
