import React from "react"
import BallySportsNHL from "src/components/page/bally-sports-nhl"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_stars.videoUrl}
    staticAnnotation={videos.bally_stars.staticAnnotation}
    title="Watch Dallas hockey live"
    subtitle="STREAM THE STARS ON BALLY SPORTS SOUTHWEST"
    customNetworks={["ballysportssouthwest"]}
  />
)