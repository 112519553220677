import React, { useState, useEffect } from 'react'

export const useEPLImage = () => {
  // EPL Static image
  const desktopPoster =
    'https://assets-imgx.fubo.tv/marketing/lp/images/posters/epl-static-bg-horizontal.jpg?w=1920&ch=width&auto=format,compress'
  const mobilePoster =
    'https://assets-imgx.fubo.tv/marketing/lp/images/posters/epl-static-bg-vertical.jpg?w=600&ch=width&auto=format,compress'

  const [posterState, setPosterState] = useState(desktopPoster)
  const [isMobile, setIsMobile] = React.useState(false)

  const checkIsMobile = () => {
    return window.innerWidth <= 768
  }

  useEffect(() => {
    function handleResize() {
      console.log(checkIsMobile())
      setIsMobile(checkIsMobile())
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isMobile) {
      setPosterState(mobilePoster)
    } else {
      setPosterState(desktopPoster)
    }
  }, [isMobile])

  return posterState
}
