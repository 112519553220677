import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerCode="pro-milwaukee-bucks-14d-trial"
    offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
    video={videos.bally_bucks.videoUrl}
    staticAnnotation={videos.bally_bucks.staticAnnotation}
    title="Watch Milwaukee Bucks basketball"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream Bally Sports from any device. No cable required. Regional restrictions apply."
    networkSet="basketball"
    customNetworks={['ballysports']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-milwaukee-bucks-v2.png?ch=width&auto=format,compress"
    logoHeight="120px"
    league="NBA"
  />
)
