import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { DefaultCtaText, DefaultCtaTextShort } from 'src/modules/utils/constants'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { track } from 'src/modules/analytics/track'

const BundleCard = ({
  isFeatured,
  featureText,
  footnoteInside,
  footnoteOutside,
  eyebrowHeadline,
  title,
  headlineSeparator,
  headlineDescription,
  priceHeader,
  priceDiscount,
  subheadline,
  listItems,
  // eventually remove ctaQuery once I finish migrating ctaUrl
  ctaQuery,
  ctaUrl,
  offerSlug,
  ctaText = DefaultCtaTextShort,
  isMinimized,
  promoImg,
  assetWidth,
  contentWidth,
  imageHeight,
  single,
  bulletSize,
}) => {
  const formattedCTAQuery = ctaQuery ? `${defaultCTAURI}?${ctaQuery}` : `${defaultCTAURI}`

  const handleOnClick = e => {
    const eventProperties = {
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'cta_plan_card',
        // content_name: title,
        offer_slug: offerSlug,
      },
    }
    track('lp_interaction', eventProperties)
  }

  return (
    <StyledBundleCard contentWidth={contentWidth} assetWidth={assetWidth} imageHeight={imageHeight}>
      <a className={`card`} href={ctaUrl || formattedCTAQuery} onClick={handleOnClick}>
        {featureText && (
          <div className={`featuredColorCap ${single ? 'no-absolute' : ''}`}>
            <span className="capText">{featureText}</span>
          </div>
        )}
        <div className={`main-content ${promoImg ? 'with-image' : ''}`}>
          <div className="promotionCopy">
            {eyebrowHeadline && <h4 className="eyebrowHeadline">{eyebrowHeadline}</h4>}
            <div className="promotionHeader">
              <div>{title && <h3 className="title">{title}</h3>}</div>
              {isMinimized && (
                <div>
                  <MinimizedButton href={ctaUrl || formattedCTAQuery} onClick={handleOnClick}>
                    <span>{ctaText}</span>
                  </MinimizedButton>
                </div>
              )}
            </div>
            {headlineSeparator && <hr />}
            <div className="promotionContent">
              <div className="priceHeader">
                {priceDiscount && <span className="price"> {priceDiscount}</span>}
                <span className={`price ${priceDiscount ? 'discounted' : ''}`}>{priceHeader}</span>
                {subheadline && <div className="subheader">{subheadline}</div>}
              </div>
              {headlineDescription && <div className="subtitle">{headlineDescription}</div>}
              <ul className="feature-list">
                {listItems &&
                  listItems.map((item, i) => (
                    <li className="list-item" key={i} bulletSize={bulletSize}>
                      <span>✔ </span>
                      {item}
                    </li>
                  ))}
              </ul>
            </div>
            <StyledButton
              href={ctaUrl || formattedCTAQuery}
              onClick={handleOnClick}
              className={`${isMinimized ? 'mobile' : ''} ${isFeatured ? 'primary' : ''}`}
            >
              <span>{ctaText}</span>
            </StyledButton>
            {footnoteInside && <div className="footnote dark">{footnoteInside}</div>}
          </div>
          {promoImg && (
            <div className="promotionAssets">
              {promoImg.map((img, i) => (
                <div className="image-wrapper" key={i}>
                  <img
                    src={img[0]}
                    srcSet={`${img[0]} 1x,${img[1]} 2x`}
                    sizes="(min-width: 1000px) 580px, (min-width: 530px) 215px, 25vw"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </a>
      {footnoteOutside && (
        <div
          className={`footnote footnote--outside light`}
          dangerouslySetInnerHTML={{ __html: footnoteOutside }}
        />
      )}
    </StyledBundleCard>
  )
}

export default BundleCard

const cardBreakpoint = 992
const desktopBreak = cardBreakpoint + 'px'
const mobileBreak = cardBreakpoint - 1 + 'px'

const StyledBundleCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: ${mobileBreak}) {
    width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .featuredColorCap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background: rgb(71, 166, 29);
    border-radius: 6px 6px 0px 0px;
    z-index: 2;

    @media screen and (min-width: ${desktopBreak}) {
      &:not(.no-absolute) {
        position: absolute;
        height: 53px;
        top: -47px;
      }
    }

    .capText {
      color: #ffffff;
      font-size: 26px;
      line-height: 2;
      font-family: Qanelas-Bold;
      font-weight: bold;
      text-align: center;
      letter-spacing: -0.19px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
  }

  .main-content {
    height: 100%;
    display: flex;
    @media (min-width: ${desktopBreak}) {
      &.with-image {
        flex-direction: row;
      }
    }
    @media (max-width: ${mobileBreak}) {
      &.with-image {
        flex-direction: column;
      }
    }
  }

  .promotionCopy {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 38px;
    box-sizing: border-box;
    flex-grow: 1;
    ${'' /* height: 100%; */}

    @media (max-width: ${mobileBreak}) {
      padding: 40px 10%;
    }

    @media (min-width: ${desktopBreak}) {
      min-width: ${props => props.contentWidth || '430px'};
      max-width: 500px;
      ${'' /* min-width: 430px;
      max-width: ${props => props.contentWidth || "600px"}; */}
    }

    .eyebrowHeadline {
      width: 100%;
      color: #424242;
      font-family: Qanelas-Bold, sans-serif;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
      margin-top: 0;
    }

    hr {
      background: rgb(104, 104, 104, 0.2);
      height: 1px;
      width: 95%;
      border: 0;
      margin: 20px auto;
    }

    .promotionHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: ${mobileBreak}) {
        width: 100%;
        text-align: left;

        h3 {
          text-align: left;
        }
      }
    }

    .title {
      color: rgb(66, 66, 66);
      font-size: 32px;
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.2;
      text-align: left;
      margin-bottom: 5px;
    }

    .subheader {
      width: 100%;
      color: rgb(58, 58, 58);
      font-size: 20px;
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.25;
      position: relative;
      text-align: left;
      margin-bottom: 6px;
    }

    .subtitle {
      color: rgba(66, 66, 66, 0.7);
      font-size: 18px;
      font-family: Qanelas-Medium;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }

  .promotionContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .feature-list {
      margin: 0;
      width: 100%;
      padding: 0;
      .list-item {
        color: #424242;
        opacity: 0.7;
        font-size: ${props => props.bulletSize || '18px'};
        font-family: Qanelas-Medium;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 22px;
        margin-bottom: 7px;
        @media (max-width: ${mobileBreak}) {
          font-size: 16px;
        }
        &:first-parent {
          margin-top: 15px;
        }
        span {
          color: blue;
        }
      }
    }

    .highlight {
      color: rgb(58, 58, 58);
      font-size: 20px;
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.25;
      position: relative;
      text-align: left;
      margin-bottom: 6px;
      span {
        margin-bottom: 4px;
        display: block;
      }
    }

    .mobile-images {
      display: none;
    }

    @media (max-width: ${mobileBreak}) {
      .mobile-images {
        display: flex;
        margin: 25px 0 10px 0;

        img {
          border-radius: 0;
          max-width: 215px;
          width: 100%;
        }
      }
    }
  }

  .priceHeader {
    width: 100%;
    color: rgb(58, 58, 58);
    font-size: 26px;
    font-family: Qanelas-Bold, sans-serif;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 27.6px;
    position: relative;
    margin-bottom: 10px;

    color: rgb(66, 66, 66);
    font-size: 28px;
    font-family: Qanelas-Bold, sans-serif;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1;
    text-align: left;

    @media (max-width: 1265px) {
      ${'' /* height: 58px; */}
    }
    @media (max-width: 1213px) {
      height: auto;
    }

    span {
      display: block;
    }

    .price {
      line-height: 1.2;
      margin-bottom: 4px;
      &.discounted {
        text-decoration: line-through;
        font-family: Qanelas, sans-serif;
        font-weight: 400;
        font-size: 24px;
        text-decoration-color: #fa4616;
      }
    }

    .asterisk {
      font-size: 16px;
      position: absolute;
    }
    .strikethrough {
      color: rgb(58, 58, 58, 0.6);
      font-size: 15px;
      text-decoration: line-through;
      line-height: 28px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    height: 100%;

    transition: transform 0.5s;

    &:hover {
      transform: scale(1.01);
    }

    @media (max-width: ${mobileBreak}) {
      width: 100%;
      max-width: 600px;

      .desktop-images {
        display: none;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .footnote {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    font-family: Qanelas-Medium;
    font-weight: 500;
    flex-direction: row;
    letter-spacing: 0px;
    line-height: 16px;
    padding-top: 20px;
    padding-bottom: 15px;
    text-align: center;

    &.dark {
      color: #424242;
    }

    &.light {
      color: #ffffff;
    }

    &.footnote--outside {
      @media (min-width: ${desktopBreak}) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
      }
      &.no-absolute {
        @media (min-width: ${desktopBreak}) {
          position: relative;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }

    .asterisk {
      font-size: 14px;
      position: relative;
      left: -5px;
      top: 0px;
    }
  }

  .promotionAssets {
    display: flex;
    flex-direction: row;
    gap: 1px;

    div.image-wrapper {
      width: 100%;
      display: flex;
      @media (min-width: ${desktopBreak}) {
        height: ${props => props.imageHeight || 'auto'};
      }
      img {
        width: 100%;
        height: auto;
      }
    }

    @media (min-width: ${desktopBreak}) {
      max-width: ${props => props.assetWidth || 'none'};
      flex-direction: column;
    }
  }
`

const StyledButton = styled.div`
  position: relative;
  margin: 28px auto 0px;
  border-radius: 3px;
  font-family: 'Qanelas', sans-serif;
  background: #ffffff;
  border: 2px solid #000000;
  display: flex;
  justify-content: center;
  min-height: 55px;
  width: 100%;
  max-width: 100%;

  :hover {
    ${'' /* border: 2px solid #666666; */}
    span {
      background: #ffffff;
      width: 100%;
      cursor: pointer;
    }
  }

  span {
    background: #ffffff;
    padding: 18px 20px;
    border-radius: 3px;
    color: #000000;
    font-size: 24px;
    font-family: 'Qanelas-Bold', sans-serif;
    text-align: center;
    letter-spacing: -0.17px;
    line-height: 20px;
    text-transform: uppercase;
    @media screen and (max-width: 736px) and (min-width: 320px) {
      font-size: 20px;
      width: 100%;
      max-width: 242px;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 75px;
  }
  @media (max-width: ${mobileBreak}) {
    ${'' /* max-width: 90%; */}
    margin-bottom: 15px;
  }
  &.primary {
    background: #fa4616;
    border: 2px solid #fa4616;
    span {
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      background: #fa4616;
      color: rgb(255, 255, 255);
    }
    :hover {
      span {
        background: #b33922;
      }
    }
  }
  &.mobile {
    @media (min-width: ${desktopBreak}) {
      display: none;
    }
  }
`

const MinimizedButton = styled.a`
  color: #0645ad;
  font-family: Qanelas-Bold, sans-serif;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 15px;
  text-decoration: underline;
  white-space: nowrap;
  @media (max-width: ${mobileBreak}) {
    display: none;
  }
`
