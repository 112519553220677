import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_timberwolves.videoUrl}
    staticAnnotation={videos.bally_timberwolves.staticAnnotation}
    title="Watch Minnesota basketball live"
    subtitle="STREAM THE TIMBERWOLVES ON BALLY SPORTS NORTH"
    customNetworks={['ballysportsnorth']}
  />
)
