import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca'
import { videos } from 'src/constants/videos'
export default props => (
  <CanadaTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    // replace this with willow add-on
    addonCode="sports-plus-ca"
    video={videos.cricket.videoUrl}
    title="Watch live cricket matches"
    subtitle="CATCH THE BIGGEST EVENTS OF THE YEAR"
    tagline="Stream them live from your TV & other devices"
    networkSet="canada"
    plan="sportsOnly"
    planCardOverride="cricket"
    soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_cricket.jpg?w=750&ch=width&auto=format,compress"
    customNetworks={['willow']}
  />
)
