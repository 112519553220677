import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/RootSports_2021_v3.mp4'}
      staticAnnotation={networkLogos.rootsports}
      title="Watch ROOT SPORTS with Fubo"
      subtitle="SEE THE KRAKEN, BLAZERS, MARINERS AND MORE"
      tagline="Stream it live anytime, anywhere, from any device. Regional restrictions apply."
      networkSet="sports"
      customNetworks={['rootsports']}
    />
  )
}
