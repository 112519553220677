import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import { getURIParamValue } from 'src/modules/utils/analytics'

export default props => {
  const [hasCode, setHasCode] = useState(false)
  const offerCode = getURIParamValue(props.location.search, 'offer')

  useEffect(() => {
    if (offerCode) {
      setHasCode(true)
    }
  }, [offerCode])

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      ctaText="Acceder Ahora"
      offerBanner={
        hasCode ? (
          <>
            ✔ ¡Oferta Especial Exclusiva de SEIS!
            <br />
            Disfruta de $10 de descuento al mes en los próximos 3 meses
          </>
        ) : null
      }
      video={videos.latino.videoUrl}
      annotations={videos.latino.annotations}
      title="Ahorra en TV en vivo para toda la familia"
      subtitle={!hasCode && "Accede ahora por solo $24.99 el primer mes"}
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
      noteLink="/la/learn/"
      offerCode={offerCode}
      quarterlyOnly={hasCode}
    />
  )
}
