import React from 'react'
import { FuboLogo } from 'src/components/icons/FuboLogo'
import { palette, typography } from 'src/modules/design-system/styles'
import styled from 'styled-components'

const CompareHeader = () => {
  return (
    <StyledCompareHeader role="banner">
      <header className="header-content">
        <a className="logo" href="/">
          <FuboLogo width={70} height={29} />
        </a>
        <div className="right-buttons">
          <a href="https://www.fubo.tv/signin" className="btn signin">
            SIGN IN
          </a>
          <a href="https://www.fubo.tv/signup" className="btn signup">
            START FREE TRIAL
          </a>
        </div>
      </header>
    </StyledCompareHeader>
  )
}

const StyledCompareHeader = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: sticky;
  z-index: 20;
  top: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .link {
    text-decoration: underline;
  }

  .header-content {
    padding: 12px 30px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-top: -2px;

    .right-buttons {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 10px;
    }

    a {
      ${typography.body.M};
      color: #b6b6b6;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn {
      border-radius: 1px;
      padding: 6px 13px;
      ${typography.interactive.S};

      &.signin {
        color: #fff;
        border: 2px solid #b6b6b6;
        background-color: #000;
      }

      &.signup {
        background-color: ${palette.orange100};
        color: ${palette.white};
        border: 2px solid ${palette.orange100};
        margin-left: 10px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .header-content {
      padding: 10px 15px;
      flex-wrap: wrap;

      a {
        ${typography.heading.XS};
      }

      .left-links {
        margin-right: 15px;
      }

      .btn {
        padding: 4px 10px;
      }
    }
  }

  @media only screen and (max-width: 355px) {
    .header-content {
      flex-direction: column;

      .logo {
        margin-bottom: 10px;
      }

      img {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
`

export default CompareHeader
