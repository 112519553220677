import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    //nba league pass addon
    // addonCode="nbalp"
    video={videos.nbatv.videoUrl}
    title="Stream NBA TV on Fubo"
    subtitle="CATCH LIVE GAMES, SHOWS AND HIGHLIGHTS"
    tagline="Any time, any place, on all your devices"
    networkSet="basketball"
    league="nba"
    showRSN={true}
  />
)
