import React from 'react'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { heading } from 'src/components/template/compare/constants/typography'
import styled from 'styled-components'

const CompareHeroSection = ({ competitor, data }) => {
  const { title, subtitle } = data
  const background = 'https://assets-imgx.fubo.tv/marketing/lp/images/compare/compare_hero_2.jpg'
  return (
    <StyledCompareHeroSection className="compare-hero">
      <div className="background">
        <img src={background} />
      </div>
      <CompareContainer className="content">
        <h1>
          <span className="fubo-logo">Fubo</span>
          <span className="vs"> vs </span>
          <span className="competitor-logo">{competitor}</span>
        </h1>
        <div className="text">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
        </div>
      </CompareContainer>
    </StyledCompareHeroSection>
  )
}

export default CompareHeroSection

const StyledCompareHeroSection = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 330px;

  ${breakpointQuery.desktop} {
    min-height: 800px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          #000f26 0%,
          rgba(0, 15, 38, 0.2) 50%,
          rgba(0, 15, 38, 0) 100%
        ),
        radial-gradient(66.81% 130% at 50% 50%, rgba(0, 15, 38, 0) 0%, rgba(0, 15, 38, 0.8) 100%),
        rgba(0, 15, 38, 0.6);
    }
  }
  .content {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 33px 30px;
    margin: 0 auto;

    @media (max-width: 480px) {
      max-width: 250px;
    }
    ${breakpointQuery.mobile} {
      max-width: 500px;
    }
    ${breakpointQuery.desktop} {
      flex-direction: row-reverse;
      gap: 50px;
      ${'' /* max-width: 1480px; */}
    }

    h1 {
      flex: 1;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      font-family: 'Qanelas';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 1;
      color: #ffffff;
      white-space: nowrap;
      /* identical to box height, or 166% */

      ${breakpointQuery.desktop} {
        font-size: 50px;
        gap: 20px;
      }

      @media (min-width: 1000px) {
        font-size: 66px;
      }

      .fubo-logo {
      }
      .vs {
        font-family: 'Qanelas';
        font-style: normal;
        font-weight: 500;
        font-size: 10.2222px;
        line-height: 21px;
        /* identical to box height, or 209% */
        color: #ffffff;
        ${'' /* position: absolute; */}

        ${breakpointQuery.desktop} {
          font-size: 18.7407px;
          line-height: 39px;
        }
      }
      .competitor-logo {
      }
    }
    .text {
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      ${breakpointQuery.desktop} {
        text-align: left;
        align-items: flex-start;
      }

      h2 {
        ${heading.lg}
        text-shadow: 0px 0px 40px #000f26;
        color: #ffffff;
        ${breakpointQuery.desktop} {
          ${heading.xxl}
        }
      }
      h3 {
        font-family: 'Qanelas';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        /* or 147% */

        text-align: center;

        /* Grey / Grey-400 */

        color: #e5e6e6;

        ${breakpointQuery.desktop} {
          text-align: left;
          ${heading.md}
        }
      }
    }
  }
`
