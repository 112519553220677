import React, { useEffect, useState } from 'react'
import { newPlanData, newTabData } from 'src/constants/caPlanData'
import { useSelector } from 'react-redux'
import { track } from 'src/modules/analytics/track'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import PlanCardWithImage from 'src/components/common/PlanCardWithImage'
import styled from 'styled-components'
import {
  selectCanadianChannels,
  selectCanadianEntertainmentChannels,
  selectCanadianEssentialsChannels,
} from 'src/modules/reducers/plans/selectors'
import TabSelector from 'src/components/common/TabSelector'

const BundlePlansCAControlVariant = ({
  title = 'Which plan is right for you?',
  subtitle = 'Canada’s home to streaming live soccer and top entertainment',
  plan,
  offerCode,
  soccerPlanCardImage,
  premiumPlanCardImage,
  ctaText,
  preselectPlanLength,
  forcePlanLength,
  planCardOverride,
}) => {
  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const canadianEntertainmentChannels = useSelector(selectCanadianEntertainmentChannels)
  const canadianSoccerChannels = useSelector(selectCanadianEssentialsChannels)
  const canadianPremiumChannels = useSelector(selectCanadianChannels)

  const channels = {
    entertainment: canadianEntertainmentChannels.length,
    soccer: canadianSoccerChannels.length,
    premium: canadianPremiumChannels.length,
  }

  const handleTabClick = selected => {
    setActiveTab(selected)
    const eventProperties = {
      ...analyticsProperties,
      ui_data: {
        category: 'clicked',
        event_data: {
          component: 'cta_plan_length_tab',
          content_name: selected,
        },
      },
    }
    console.log('eventProperties', eventProperties)
    track('lp_interaction', eventProperties)
  }

  const tabs = ['Monthly', 'Quarterly', 'Annual']

  const tabsSavings = {
    monthly: '',
    quarterly: newTabData.quarterly.tabNote,
    annual: newTabData.annual.tabNote,
  }

  const [activeTab, setActiveTab] = React.useState(tabs[2])
  const [planData, setPlanData] = React.useState(newPlanData())
  const [overrideOffer, setOverrideOffer] = useState(null)

  useEffect(() => {
    if (plan === 'entertainment') {
      setActiveTab(tabs[1])
    }
    if (preselectPlanLength) {
      setActiveTab(preselectPlanLength)
    }
    if (forcePlanLength) {
      setActiveTab(forcePlanLength)
    }
  }, [plan, preselectPlanLength, forcePlanLength])

  useEffect(() => {
    let data = { ...newPlanData(channels, activeTab.toLowerCase(), plan !== 'entertainment') }

    // Adjust pricing if soccerOffer
    switch (planCardOverride) {
      case 'cricket':
        data.soccer.title = 'Sports + Sports Plus'
        data.soccer.subtitle = 'Best for Cricket Fans'
        data.soccer.pricePerMonth = {
          monthly: 'C$35.98/mo',
          quarterly: 'C$31.65/mo',
          annual: 'C$27.82/mo',
        }
        data.soccer.discountedPricePerMonth = {
          monthly: '',
          quarterly: 'C$24.99/mo',
          annual: 'C$23.24/mo',
        }
        data.soccer.savingsNote = {
          monthly: '',
          quarterly: 'Save $30 over 3 months*',
          annual: 'Save $134 over 12 months*',
        }
        data.soccer.totalPrice = {
          monthly: 'C$35.98',
          quarterly: 'C$94.96',
          annual: 'C$333.87',
        }
        data.soccer.discountedTotalPrice = {
          monthly: '',
          quarterly: 'C$74.96',
          annual: 'C$278.87',
        }
        data.soccer.cardFootnote = {
          monthly: <></>,
          quarterly: (
            <>
              *Discount based on 3 months of service of Sports Monthly plan. Prepaid for 3 months at
              C$74.96. Renews at full price of C$94.96.
            </>
          ),
          annual: (
            <>
              {' '}
              *Discount based on 12 months of service of Sports Monthly plan. Prepaid for 12 months
              at C$278.87. Renews at full price of C$333.87.
            </>
          ),
        }
        data.soccer.listItems.splice(1, 0, {
          available: true,
          text: 'Cricket Matches with Willow TV',
        })
        break
      case 'tottenhamOffer':
        data.soccer.offerCode.annual = 'canada-year-essentials-tottenham-spurs'
        data.soccer.discountedPricePerMonth.annual = 'C$20.83/mo'
        data.soccer.savingsNote.annual = '$30 off your first bill'
        data.soccer.discountedTotalPrice = 'C$249.99/mo'
        data.soccer.cardFootnote.annual = (
          <>*Prepaid for 12 months at C$249.99. Renews at full price of C$279.99.</>
        )
        break
      case 'soccerOffer':
        data.soccer.offerCode = {
          monthly: 'ca-recu-mk-sports-mo-nt-5amtoff1-v1',
          quarterly: 'ca-recu-mk-sports-qt-nt-15amtoff1-v1',
          annual: 'ca-recu-mk-sports-yr-nt-20amtoff1-v1',
        }
        data.soccer.discountedPricePerMonth = {
          monthly: 'C$24.99/mo',
          quarterly: 'C$21.66/mo',
          annual: 'C$21.66/mo',
        }
        data.soccer.savingsNote = {
          monthly: '$5 off your first bill',
          quarterly: '$15 off your first bill',
          annual: '$20 off your first bill',
        }
        data.soccer.discountedTotalPrice = {
          monthly: 'C$24.99/mo',
          quarterly: 'C$26.66/mo',
          annual: 'C$23.33/mo',
        }
        data.soccer.cardFootnote = {
          monthly: <>*Renews at C$24.99/mo.</>,
          quarterly: <>*Prepaid for 3 months at C$64.97. Renews at C$79.97/3mo.</>,
          annual: <>*Prepaid for 12 months at C$259.99. Renews at C$279.99/yr.</>,
        }
        break
      case 'menInBlazersOffer':
        data.soccer.offerCode.quarterly = 'canada-quarter-essentials-men-in-blazers'
        data.soccer.discountedPricePerMonth.quarterly = 'C$18.32/mo'
        data.soccer.savingsNote.quarterly = '$25 off your first bill'
        data.soccer.discountedTotalPrice = 'C$54.97/mo'
        data.soccer.cardFootnote.quarterly = (
          <>*Prepaid for 3 months at C$54.97. Renews at C$79.97/3mo.</>
        )
        break
      case 'ftfOffer':
        data.soccer.offerCode.quarterly = 'canada-quarter-essentials-ftf-30-off'
        data.soccer.discountedPricePerMonth.quarterly = 'C$16.66/mo'
        data.soccer.savingsNote.quarterly = '$30 off your first bill'
        data.soccer.discountedTotalPrice = 'C$49.97/mo'
        data.soccer.cardFootnote.quarterly = (
          <>*Prepaid for 3 months at C$49.97. Renews at C$79.97/3mo.</>
        )
        break
      case 'superChannelOffer':
        data.entertainment.title = 'Entertainment Plus'
        data.entertainment.price = 'C$24.98/mo'
        data.entertainment.offerCode = {
          monthly: 'canada-month-entertainment-plus',
          quarterly: 'canada-quarter-entertainment-plus',
        }
        data.entertainment.pricePerMonth = {
          monthly: 'C$24.98/mo',
          quarterly: 'C$23.33/mo',
        }
        data.entertainment.totalPrice = {
          monthly: 'C$24.98',
          quarterly: 'C$69.99',
        }
        data.entertainment.discountedPricePerMonth = {
          monthly: '',
          quarterly: 'C$11.66/mo',
        }
        data.entertainment.discountedTotalPrice = {
          monthly: '',
          quarterly: 'C$34.99',
        }
        data.entertainment.savingsNote = {
          monthly: '',
          quarterly: '50% off for 3 months',
        }
        data.entertainment.listItems.splice(-1, 0, {
          available: true,
          text: 'All Super Channel networks',
        })
        data.premium.listItems.splice(-1, 0, {
          available: false,
          text: 'Super Channel networks',
        })
        data.entertainment.cardFootnote = {
          monthly: '',
          quarterly: <>*Prepaid for 3 months at C$34.99. Renews at C$69.99/3mo.</>,
        }
        data.entertainment.listItems.splice(-1, 0, data.entertainment.listItems.splice(1, 1)[0])
        break
      case 'mlbtvOffer':
        data.soccer.title = 'Sports & MLB.TV'
        data.soccer.subtitle = 'Best for Sports Fans'
        data.soccer.offerCode = {
          monthly: 'canada-month-essentialsmlbtv-v3-promo',
          quarterly: 'canada-quarter-essentials-v5-mlbtv-promo',
          annual: 'canada-year-essentials-v5-promo-mlbtv',
        }
        data.soccer.pricePerMonth = {
          monthly: 'C$49.99/mo',
          quarterly: 'C$49.99/mo',
          annual: 'C$29.99/mo',
        }
        data.soccer.discountedPricePerMonth = {
          monthly: 'C$39.99/mo',
          quarterly: 'C$39.99/mo',
          annual: 'C$27.99/mo',
        }
        data.soccer.savingsNote = {
          monthly: '$10 off for 2 months',
          quarterly: '$30 off for 2 quarters',
          annual: '$24 off the first year',
        }
        data.soccer.totalPrice = {
          monthly: 'C$49.99',
          quarterly: 'C$149.97',
          annual: 'C$359.99',
        }
        data.soccer.discountedTotalPrice = {
          monthly: 'C$39.99',
          quarterly: 'C$119.97',
          annual: 'C$335.88',
        }
        data.soccer.cardFootnote = {
          monthly: <>*Offer valid for first 2 months, then renews at C$49.99/mo.</>,
          quarterly: (
            <>
              *Prepaid for 3 months at C$119.97 for the first 6 months. Renews at C$149.97/3mo after
              2 billing periods.
            </>
          ),
          annual: <>*Prepaid for 12 months at C$335.88. Renews at C$359.99/yr.</>,
        }
        data.soccer.listItems.splice(1, 0, {
          available: true,
          text: 'MLB.TV, featuring 2,000+ Live Games per year',
        })
        break
      case 'sabresPriceTest':
        data.soccer.listItems.unshift({ available: true, text: 'LIVE Buffalo Sabres games' })
        data.premium.listItems.unshift({ available: true, text: 'LIVE Buffalo Sabres games' })

        data.soccer.offerCode = {
          monthly: 'canada-month-essentials-buffalo-sabres-mid-promo',
          quarterly: 'canada-quarter-essentials-buffalo-sabres-mid-promo',
          annual: 'canada-year-essentials-buffalo-sabres-mid-promo',
        }
        data.soccer.discountedPricePerMonth = {
          monthly: 'C$9.99/mo',
          quarterly: 'C$9.33/mo',
          annual: 'C$8.33/mo',
        }
        data.soccer.savingsNote = {
          monthly: '60% off for 12 months',
          quarterly: '62% off for 12 months',
          annual: '66% off for 12 months',
        }
        data.soccer.discountedTotalPrice = {
          monthly: 'C$9.99',
          quarterly: 'C$27.99',
          annual: 'C$99.99',
        }
        data.soccer.cardFootnote = {
          monthly: <>*Renews at C$29.99/mo after 12 months.</>,
          quarterly: <>*Prepaid for 3 months at C$27.99. Renews at C$79.97/3mo after 12 months.</>,
          annual: <>*Prepaid for 12 months at C$99.99. Renews at C$279.99/yr.</>,
        }
        break
      default:
        break
    }

    if (forcePlanLength && offerCode) {
      setOverrideOffer(offerCode)
    }

    let filteredPlan
    let filteredPlan2
    switch (plan) {
      case 'entertainment':
        filteredPlan = 'soccer'
        break
      case 'soccer':
        filteredPlan = 'entertainment'
        break
      case 'premium':
        filteredPlan = 'entertainment'
        filteredPlan2 = 'soccer'
        break
      case 'sportsOnly':
        filteredPlan = 'entertainment'
        filteredPlan2 = 'premium'
        break
      default:
        break
    }

    if (soccerPlanCardImage) {
      data.soccer.promoImg = soccerPlanCardImage
    }

    if (premiumPlanCardImage) {
      data.premium.promoImg = premiumPlanCardImage
    }

    if (filteredPlan) {
      delete data[filteredPlan]
    }
    if (filteredPlan2) {
      delete data[filteredPlan2]
    }

    setPlanData(data)
  }, [activeTab, plan, soccerPlanCardImage, premiumPlanCardImage])

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      {!forcePlanLength && (
        <div className="section-tabs">
          <TabSelector
            tabs={tabs}
            activeTab={activeTab.toLowerCase()}
            handleTabClick={handleTabClick}
            tabsSavings={tabsSavings}
          />
        </div>
      )}
      <div className="section-content">
        {planData &&
          Object.entries(planData).map(([cardPlan, card], index) => {
            if (card.pricePerMonth.hasOwnProperty(activeTab.toLowerCase())) {
              let listItems = [...card.listItems]
              if (cardPlan === 'entertainment') {
                if (activeTab.toLowerCase() === 'monthly') {
                  listItems.pop()
                  listItems.push({ available: true, text: 'Cloud DVR - 250 hours of space' })
                } else if (activeTab.toLowerCase() === 'quarterly') {
                  listItems.pop()
                  listItems.push({ available: true, text: 'Cloud DVR - 1000 hours of space' })
                }
              }

              // const NoFreeTrialDisclaimer = () => (
              //   <>
              //     *No free trial available. <br />
              //   </>
              // )

              // const CardFootnote = () => card.cardFootnote?.[activeTab.toLowerCase()] || null

              return (
                <PlanCardWithImage
                  key={index}
                  promoImg={card.promoImg}
                  plan={card.plan}
                  length={activeTab}
                  title={card.title}
                  subtitle={card.subtitle}
                  price={card.pricePerMonth[activeTab.toLowerCase()]}
                  discountedPrice={card.discountedPricePerMonth?.[activeTab.toLowerCase()]}
                  savingsNote={card.savingsNote?.[activeTab.toLowerCase()]}
                  listItems={listItems}
                  offerCode={overrideOffer || card.offerCode[activeTab.toLowerCase()]}
                  // footnote={
                  //   // <>
                  //   //   {/* {plan === 'entertainment' && card.plan !== 'entertainment' && (
                  //   //     <NoFreeTrialDisclaimer />
                  //   //   )} */}
                  //   <CardFootnote />
                  //   // </>
                  // }
                  footnote={card.cardFootnote?.[activeTab.toLowerCase()] || null}
                  ctaText={ctaText || card.ctaText}
                  // btnColor={card.ctaText && '#000000'}
                />
              )
            }
          })}
      </div>
    </StyledBundleSection>
  )
}

export default BundlePlansCAControlVariant

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 90px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-family: Qanelas-Medium;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 600px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin: 0 auto 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-family: 'Qanelas-Medium', sans-serif;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
    }
  }
`
