const initialState = {
  analyticsReady: false,
  analyticsPropertiesReady: false,
  pageViewSent: false,
  parameters: null,
  properties: {
    data: {
      device_context: {
        user_agent: null,
        os: null,
        browser: null,
        screen_height: null,
        screen_width: null,
        device_name: null,
      },
      event_context: {
        referrer: null,
        url: null,
        page: null,
      },
      location_context: {
        country_code: null,
        dma: null,
      },
    },
    device_category: null,
    event_category: null,
    event_id: null,
    event_time: null,
    spec_version: null,
    event_metadata: {
      referrer: null,
      url: null,
      domain: null,
      url_slug: null,
      language: null,
    },
  },
}

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ANALYTICS_INIT_SUCCESS":
      return {
        ...state,
        analyticsReady: true,
      }
    case "UPDATE_ANALYTICS_PROPERTIES":
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload,
          data: {
            ...state.properties.data,
            device_context: {
              ...state.properties.data.device_context,
              ...(action.payload.data.device_context || {}),
            },
            event_context: {
              ...state.properties.data.event_context,
              ...(action.payload.data.event_context || {}),
            },
            location_context: {
              ...state.properties.data.location_context,
              ...(action.payload.data.location_context || {}),
            },
          },
          event_metadata: {
            ...state.properties.event_metadata,
            ...(action.payload.event_metadata || {}),
          },
        },
      }
    case "ANALYTICS_PROPERTIES_READY":
      return {
        ...state,
        analyticsPropertiesReady: true,
      }
    case "UPDATE_PARAMETERS":
      return {
        ...state,
        parameters: {
          ...state.parameters,
          ...action.payload,
        },
      }
    case "PAGE_VIEW_SENT":
      return {
        ...state,
        pageViewSent: true,
      }
    default:
      return state
  }
}
