import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerCode="pro-affiliation-15-off"
    offerBanner={
      <>
        ✔ Special offer for diehard CHGO fans!
        <br />
        Get 15% off your first month of Fubo
      </>
    }
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-chgo.png?w=451&ch=width&auto=format,compress"
    logoHeight="42px"
    video={videos.generic_baseball.videoUrl}
    title="Watch Chicago baseball live"
    subtitle="STREAM GAMES ALL SEASON WITHOUT CABLE"
    tagline="Anytime. Anywhere. From any device."
    networkSet="baseball"
    customNetworks={['marquee', 'nbcschicago']}
  />
)
