const cardData = {
  en: {
    name: { monthly: 'LATINO MONTHLY PLAN', quarterly: 'LATINO QUARTERLY PLAN' },
    featureText: {
      monthly: '25% off for your first month',
      // quarterly: "$24.03 off first 3 months",
      quarterly: 'Lock in savings for 3 months',
    },
    headlineDescription: 'The best Spanish language TV, live soccer, news, entertainment and more.',
    priceHeader: {
      monthly: '$32.99/mo',
      quarterly: '$33/mo',
    },
    // latinoDiscountMonthlyPrice: '$24.99/mo*',
    listItems: [
      '60+ channels. 100+ live events.',
      'Unlimited Cloud DVR.',
      '2 Screens at once with Standard Share.',
    ],
    note: {
      monthly: '*Renews at $32.99/mo. Additional taxes and fees may apply. Cancel anytime.',
      quarterly:
        '*Prepaid for 3 months at $74.97. Renews at $99/3mo. Additional taxes and fees may apply. Cancel anytime.',
    },
    bannerTitle: {
      monthly: 'Save 25% off your first month of Fubo.',
      quarterly: 'Save $24.03 off your first 3 months of Fubo.',
    },
    bannerSubtitle: {
      monthly: 'Limited time offer.',
      quarterly: 'Limited time offer.',
    },
  },
  es: {
    name: { monthly: 'PLAN MENSUAL LATINO', quarterly: 'PLAN TRIMESTRAL LATINO' },
    featureText: {
      monthly: '25% de descuento el primer mes',
      // quarterly: "$24.03 de descuento el primer trimestre.",
      quarterly: 'Aprovecha esta oferta durante 3 meses',
    },
    headlineDescription: 'TV en español, fútbol en vivo, telenovelas, noticias y mucho más.',
    priceHeader: {
      monthly: '$32.99/mes',
      quarterly: '$33/mes',
    },
    // latinoDiscountMonthlyPrice: '$24.99/mes*',
    listItems: [
      'Más de 60 canales y 100 eventos deportivos.',
      'Cloud DVR Ilimitado.',
      '2 Pantallas a la vez con Standard Share.',
    ],
    note: {
      monthly:
        '*Se renueva a $32.99/mes. Pueden aplicarse impuestos y otros cargos. Cancela cuando quieras.',
      quarterly:
        '*Prepago por 3 meses a $74.97. Se renueva a $99/trimestre. Pueden aplicarse impuestos y otros cargos. Cancela cuando quieras.',
    },
    bannerTitle: {
      monthly: 'Ahorra un 25% tu primer mes de Fubo.',
      quarterly: 'Ahorra $24.03 tus primeros 3 meses de Fubo.',
    },
    bannerSubtitle: {
      monthly: 'Oferta por tiempo limitado.',
      quarterly: 'Oferta por tiempo limitado.',
    },
  },
}

const offerSlug = {
  monthly: 'us-recu-ga-latino-mo-7d-8amtoff1-v1',
  quarterly: 'latino-bundle-quarterly-v6',
}

export { cardData, offerSlug }
