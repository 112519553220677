import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const BundleSection = ({
  channels,
  lang = 'en',
  title = 'Which plan is right for you?',
  subTitle = 'Live stream Premier League, Serie A, Coppa Italia, Ligue 1 and more',
  tabData,
  activeTab,
  handleTabClick,
  ctaQuery,
  ctaText,
  hasFeatured,
  hasFootnote,
  children,
  id,
}) => {
  const tabs = tabData ? Object.keys(tabData) : null
  return (
    <SectionWrapper
      id={id}
      hasFeatured={hasFeatured}
      hasFootnote={hasFootnote}
      count={React.Children.toArray(children).length}
    >
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subTitle}</p>
      </div>
      {tabs?.length > 0 && (
        <div role="tablist" aria-orientation="horizontal" className="plan-tabs">
          {tabs.map(tab => (
            <button
              aria-controls={`tab-${tab}`}
              aria-selected="true"
              tabIndex="0"
              role="tab"
              onClick={() => handleTabClick(tab)}
              className={activeTab === tab ? 'active' : ''}
              key={tab}
            >
              <div className="tab-content">
                <span>{tabData[tab].name}</span>
              </div>
            </button>
          ))}
        </div>
      )}
      <div className="section-content">{children}</div>
    </SectionWrapper>
  )
}

export default BundleSection

const SectionWrapper = styled.section`
  color: #fff;
  padding: 70px 20px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: ${({ theme }) => (theme.channelTitle && theme.channelTitle.fontSize) || '46px'};
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: ${({ theme }) =>
        (theme.channelTitle && theme.channelTitle.lineHeight) || '52px'};

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-family: Qanelas-Medium;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
    }
  }

  .plan-tabs {
    ${'' /* border: 3px solid #fa4616; */}
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    max-width: 500px;
    > * {
      flex: 1 0 0;
    }
    button {
      ${'' /* Reset Button CSS */}
      border: none;
      margin: 0;
      padding: 15px 0;
      width: auto;
      overflow: visible;
      background: transparent;
      color: inherit;
      font: inherit;
      line-height: normal;
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      -webkit-appearance: none;
      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      cursor: pointer;
      background: #ffffff;
      color: #000;

      &:hover {
        background: #f0f0f0;
      }

      &.active {
        ${'' /* background: #e6e6e6; */}
        background: #000;
        color: #fff;
      }

      .tab-content {
        ${'' /* padding: 15px 18px; */}
        font-size: 18px;
        font-family: 'Qanelas-Medium', sans-serif;
        font-weight: 500;
        text-align: center;
        -webkit-letter-spacing: -0.17px;
        -moz-letter-spacing: -0.17px;
        -ms-letter-spacing: -0.17px;
        letter-spacing: -0.17px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;

    @media (min-width: 992px) {
      align-items: stretch;
      flex-direction: row;
      gap: 40px;
      padding-top: ${props => (props.hasFeatured ? '60px' : '0')};
      padding-bottom: ${props => (props.hasFootnote ? '50px' : '0')};
    }
  }
`
