import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/tennis.mp4"
      title="Mira el U.S Open en vivo en Fubo"
      subtitle="Accede ahora por solo $24.99 el primer mes"
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
    />
  )
}
