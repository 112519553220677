import React from 'react'
import styled from 'styled-components'

const CTAButton = ({ link, round, children, ...props }) => {
  return (
    <StyledCTAButton href={link} {...props} className="CTAButton">
      {children}
    </StyledCTAButton>
  )
}

export default CTAButton

const StyledCTAButton = styled.a`
  ${'' /* position: relative;
  display: inline-block;
  font-size: 23px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background: #fa4616;
  padding: 18px 50px;
  border-radius: 30px;
  font-weight: 700;
  font-family: 'Qanelas-Bold', sans-serif;
  text-align: center;
  white-space: nowrap;
  transition: background 0.5s, border-radius 0.5s, color 0.5s;
  cursor: pointer; */}

  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-style: normal;

  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.56px;
  padding: 15px 41px;
  white-space: nowrap;

  background: #e03100;
  border-radius: 4px;
  box-sizing: border-box;

  :hover {
    ${'' /* background: #b33922; */}
  }

  @media screen and (min-width: 768px) {
    font-size: 26px;
    padding: 21px 53px;
    min-width: 290px;
  }
`
