import { networkLogos } from 'src/constants/networkLogos'

export const rsnData = {
  Altitude: {
    name: 'Altitude Sports',
    displayName: 'Altitude Sports',
    bannerName: 'Altitude',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Altitude_Sports_m.png',
    livePreview: '/live/preview/altitude-sports/',
  },
  'ATTSN PT': {
    name: 'AT&T SportsNet',
    displayName: 'AT&T SportsNet',
    logo: 'https://imgx.fubo.tv/station_logos/att-sportsnet_m.png',
    livePreview: '/live/preview/att-sportsnet-pittsburgh/',
  },
  'ATTSN RM': {
    name: 'AT&T SportsNet',
    displayName: 'AT&T SportsNet',
    logo: 'https://imgx.fubo.tv/station_logos/att-sportsnet_m.png',
  },
  'ATTSN SW': {
    name: 'Space City Home Network',
    displayName: 'Space City Home Network',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/space-city_m.png',
    livePreview: '/live/preview/space-city-home-network/',
  },
  'Bally Sports': {
    name: 'Bally Sports',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysports.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Arizona': {
    name: 'BS Arizona',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsarizona.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Detroit': {
    name: 'BS Detroit',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsdetroit.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Florida': {
    name: 'BS Florida',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsflorida.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Great Lakes': {
    name: 'BS Great Lakes',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsgreatlakes.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Midwest': {
    name: 'BS Midwest',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsmidwest.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS North': {
    name: 'BS North',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsnorth.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Ohio': {
    name: 'BS Ohio',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportsohio.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS San Diego': {
    name: 'BS San Diego',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportssandiego.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS SoCal': {
    name: 'BS SoCal',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportssocal.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS South': {
    name: 'BS South',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportssouth.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Southeast': {
    name: 'BS Southeast',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportssoutheast.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Southwest': {
    name: 'BS Southwest',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportssouthwest.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS Sun': {
    name: 'BS Sun',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportssun.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  'BS West': {
    name: 'BS West',
    displayName: 'Bally Sports',
    logo: networkLogos.ballysportswest.logoWhite,
    livePreview: '/live/preview/bally-sports/',
  },
  MASN: {
    name: 'MASN',
    displayName: 'MASN',
    logo: networkLogos.masn.logoWhite,
    livePreview: '/live/preview/masn/',
  },
  Marquee: {
    name: 'Marquee',
    displayName: 'Marquee',
    logo: 'https://imgx.fubo.tv/station_logos/marquee_m.png',
    livePreview: '/live/preview/marquee-sports-network/',
  },
  MSG: {
    name: 'MSG',
    displayName: 'MSG',
    logo: 'https://imgx.fubo.tv/station_logos/msg_m.png',
    livePreview: '/live/preview/msg/',
  },
  'NBCS BA': {
    name: 'NBC Sports Bay Area',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcsbayarea.logoWhite,
  },
  'NBCS Boston': {
    name: 'NBC Sports Boston',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcsboston.logoWhite,
  },
  'NBCS CA': {
    name: 'NBC Sports CA',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcscalifornia.logoWhite,
  },
  'NBCS Chicago': {
    name: 'NBC Sports Chicago',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcschicago.logoWhite,
  },
  'NBCS Philly': {
    name: 'NBC Sports Philly',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcsphiladelphia.logoWhite,
  },
  'NBCS Wash': {
    name: 'NBC Sports Washington',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcswashington.logoWhite,
  },
  NESN: {
    name: 'NESN',
    displayName: 'NESN',
    logo: 'https://imgx.fubo.tv/station_logos/nesn_n_m.png',
    livePreview: '/live/preview/nesn/',
  },
  'Root NW': {
    name: 'Root Sports',
    displayName: 'Root Sports',
    logo: 'https://imgx.fubo.tv/station_logos/Root_Sports_m.png',
  },
  SNY: {
    name: 'YES Network',
    displayName: 'YES Network',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/YES_One_Color_White-v2.png',
    livePreview: '/live/preview/yes-network/',
  },
}
