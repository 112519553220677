import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      offerCode="pro-boston-bruins-14d-trial"
      offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
      video={videos.boston_bruins.videoUrl}
      pageTitle="Watch Boston Bruins® hockey"
      title={
        <>
          Watch Boston Bruins<sup>®</sup> hockey
        </>
      }
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream NESN from any device. No cable required."
      networkSet="hockey"
      customNetworks={['nesn']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-boston-bruins-v3.png?ch=width&auto=format,compress"
      logoHeight="100px"
    />
  )
}
