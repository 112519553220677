import React from "react"
import BallySportsMLB from "src/components/page/bally-sports-mlb"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_twins.videoUrl}
    staticAnnotation={videos.bally_twins.staticAnnotation}
    title="Watch Minnesota baseball live"
    subtitle="STREAM THE TWINS ON BALLY SPORTS NORTH"
    customNetworks={["ballysportsnorth"]}
  />
)