import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNBA
    offerCode="pro-affiliation-15-off"
    offerBanner={
      <>
        ✔ Special offer for diehard PHNX fans!
        <br />
        Get 15% off your first month of Fubo
      </>
    }
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-phnx.png?w=451&ch=width&auto=format,compress"
    logoHeight="42px"
    video={videos.bally_suns.videoUrl}
    staticAnnotation={videos.bally_suns.staticAnnotation}
    title="Watch Phoenix basketball live"
    subtitle="STREAM THE SUNS ON BALLY SPORTS ARIZONA"
    customNetworks={['ballysportsarizona']}
  />
)
