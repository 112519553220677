import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/Tottenham2022_1280x720_LP_15s.mp4'
      }
      title="Watch Tottenham soccer live"
      subtitle="STREAM EVERY PREMIER LEAGUE MATCH"
      tagline="Available all season exclusively on Fubo"
      plan="soccer"
      networkSet="canada"
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
      team="Tottenham"
      // hasScheduleBanner={true}
    />
  )
}
