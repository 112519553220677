import React, { useEffect, useState } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

import { emitter } from '@marvelapp/react-ab-test'
import { useABTest } from 'src/modules/hooks/useABTest'

const abName = 'New_Template_FAQs'
let definedVariants = [abName + '-control', abName + '-treatment']
emitter.defineVariants(abName, definedVariants)
emitter.calculateActiveVariant(abName)
let variants = {
  [`${abName}-control`]: false,
  [`${abName}-treatment`]: true,
}

export default props => {
  const [testVariant, setTestVariant] = useState(false)
  const variant = useABTest(abName, variants)

  useEffect(() => {
    if (typeof variant !== 'undefined' && variant !== null) {
      setTestVariant(variant)
    }
  }, [variant])

  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Stream Live TV & Sports"
        subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
        tagline="Watch and DVR the channels you love. No contracts, no commitment."
        showRSN={true}
        newTemplate={true}
        testTemplate={testVariant}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .hero-new .hero-content {
    h1 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
        font-size: 68px;
      }
    }
    h2 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
  }
`
