import React from 'react'
import TermsConditionsTemplate from 'src/components/template/terms-conditions'
export default props => (
  <TermsConditionsTemplate>
    <h1>FUBO ULTIMATE CAVS FAN SWEEPSTAKES</h1>
    <h2>Official Rules</h2>
    <p>
      NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING.
      VOID WHERE PROHIBITED OR RESTRICTED BY LAW. THESE RULES GOVERN ALL ASPECTS OF PARTICIPATION
      AND PRIZE AWARD.
    </p>
    <ol>
      <li>
        <h3>Promotion:</h3>These rules apply to fuboTV Media Inc.'s “Fubo Ultimate Cavs Fan
        Sweepstakes” (the “Contest”). No purchase is necessary to enter or win, and no deposit,
        entry fee, payment, or proof of purchase is necessary to participate in the Contest, as
        defined below.
      </li>
      <li>
        <h3>Sponsor.</h3>The Contest is sponsored by fuboTV Media Inc. (the “Sponsor” or “Fubo”),
        which may be contacted by email at support@fubo.tv, or by mail at 1290 Avenue of the
        Americas, New York, NY 10104, Attn: Fubo Ultimate Cavs Fan Sweepstakes.
      </li>
      <li>
        <h3>Contest Dates and Description of Contests.</h3>Contest shall be offered from 9:00 am
        Eastern Time on Thursday, February 1, 2024, to 8:59:59 am Eastern Time on Thursday, April
        11, 2024. During the Contest, participants will be offered the opportunity to enter by being
        a paid subscriber to an English language Fubo subscription plan that includes paid access to
        the regional sports network Bally Sports Ohio. Participants may visit{' '}
        <a target="_blank" href="https://support.fubo.tv/hc/en-us/articles/115005322088">
          https://support.fubo.tv/hc/en-us/articles/115005322088
        </a>{' '}
        to confirm the regional sports network(s) available to them. Sponsor reserves, in its sole
        discretion, the right to extend Contest for whatever reason without notification.
      </li>
      <li>
        <h3>Acceptance of Rules and Terms.</h3>Each participant agrees that he or she has reviewed
        and accepts these rules and the Fubo's{' '}
        <a target="_blank" href="https://legal.fubo.tv/policies/en-US/?name=privacy-policy">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a target="_blank" href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Terms of Service
        </a>
        . SECTION 22 OF SPONSOR'S TERMS OF SERVICE CONTAINS A BINDING ARBITRATION AND WAIVER OF
        CLASS ACTION CLAUSE. PLEASE REVIEW THAT CLAUSE CAREFULLY AS IT AFFECTS YOUR LEGAL RIGHTS.
        Each Participant agrees to abide by these Rules and the decisions of the Sponsor, which are
        final and binding in all respects.
      </li>
      <li>
        <h3>Eligibility.</h3>All eligible participants (“Participants”) entering the Contest must
        comply with all terms and conditions of these Rules. Participants may enter the Contest by
        having a subscription to a paid English language Fubo subscription plan that includes paid
        access to the regional sports network Bally Sports Ohio:
      </li>
      <ul>
        <li>
          The Contest is open to U.S. residents, who, on the date of entry, are at least 18 years of
          age or older.
        </li>
        <li>
          Limit of one entry per Participant per Contest Week. A “Contest Week” is the period
          occurring between 9:00 am Eastern Time of and 8:59:59 am Eastern Time of any two
          successive Thursdays occurring during the contest period. The Contest consists of ten
          Contest Weeks:
        </li>
        <ul>
          <li>
            Contest Week One: 9:00 am Eastern Time Thursday, February 1, 2024, to 8:59:59 am Eastern
            Time Thursday, February 8, 2024;
          </li>
          <li>
            Contest Week Two: 9:00 am Eastern Time Thursday, February 8, 2024, to 8:59:59 am Eastern
            Time Thursday, February 15, 2024;
          </li>
          <li>
            Contest Week Three: 9:00 am Eastern Time Thursday, February 15, 2024, to 8:59:59 am
            Eastern Time Thursday, February 22, 2024;
          </li>
          <li>
            Contest Week Four: 9:00 am Eastern Time Thursday, February 22, 2024, to 8:59:59 am
            Eastern Time Thursday, February 29, 2024;
          </li>
          <li>
            Contest Week Five: 9:00 am Eastern Time Thursday, February 29, 2024, to 8:59:59 am
            Eastern Time Thursday, March 7, 2024;
          </li>
          <li>
            Contest Week Six: 9:00 am Eastern Time Thursday, Marcy 7, 2024, to 8:59:59 am Eastern
            Time Thursday, March 14, 2024;
          </li>
          <li>
            Contest Week Seven: 9:00 am Eastern Time Thursday, Marcy 14, 2024, to 8:59:59 am Eastern
            Time Thursday, March 21, 2024;
          </li>
          <li>
            Contest Week Eight: 9:00 am Eastern Time Thursday, Marcy 21, 2024, to 8:59:59 am Eastern
            Time Thursday, March 28, 2024;
          </li>
          <li>
            Contest Week Nine: 9:00 am Eastern Time Thursday, Marcy 28, 2024, to 8:59:59 am Eastern
            Time Thursday, April 4, 2024; and
          </li>
          <li>
            Contest Week Ten: 9:00 am Eastern Time Thursday, April 4, 2024, to 8:59:59 am Eastern
            Time Thursday, April 11, 2024.;
          </li>
        </ul>
        <li>
          The Contest is only open to individuals with a paid English language Fubo subscription
          plan that includes paid access to the regional sports network Bally Sports Ohio (“Paid
          Eligible Subscription”) during any Contest Week(s). Participants may only enter the
          Contest during the Contest Week(s) during which they have a Paid Eligible Subscription.
          For the avoidance of any doubt, individuals with a Fubo trial subscription, also known as
          a free trial, are not eligible for this Contest.
        </li>
        <li>
          The Contest is open to U.S. residents who, on the date of entry, are at least 18 years of
          age or older.
        </li>
        <li>
          The officers, directors and employees of the Sponsor, its subsidiaries and affiliated
          companies, persons engaged in the development, production or distribution of materials for
          the Contest, and the immediate family members (grandparent, parent, child, sibling, and
          spouse of each) of and/or persons living in the same household as such individuals
          (whether related or not) are not eligible to participate in the Contest.
        </li>
        <li>Paid Eligible Subscription must be in good standing at time of entry.</li>
      </ul>
      <li>
        <h3>How To Enter.</h3>
      </li>
      <ul>
        <li>No purchase or payment is necessary to participate in the Contest.</li>
        <li>
          Enter the Contest by obtaining and/or maintain a Paid Eligible Subscription during any
          Contest Week(s).
        </li>
        <li>
          Sponsor shall not be liable for any problems that occur during the entry process,
          including without limitation, late, incomplete, delayed, undelivered, or misdirected
          entries, and shall not have any obligation to advise an entrant of an incomplete, invalid,
          or undeliverable submission. No illegible, incomplete, forged, or altered entries will be
          accepted.
        </li>
        <li>
          Sponsor shall have the sole and absolute discretion to determine if a Fubo subscription is
          a Paid Eligible Subscription.
        </li>
        <li>Limit one (1) entry per person.</li>
      </ul>
      <li>
        <h3>Free Alternative Method of Entry.</h3>
      </li>
      <ul>
        <li>
          Individuals may participate in this promotion without a subscription to Fubo by mailing a
          postcard containing the participant's name, telephone number, and address and the text,
          “Fubo Ultimate Cavs Fan Sweepstakes” to fuboTV Media Inc., 1290 Avenue of the Americas,
          New York, NY 10104, Attention: Fubo Ultimate Cavs Fan Sweepstakes
        </li>
        <li>Postcard Entries must be postmarked between February 1, 2024, and April 11, 2024.</li>
        <li>Limit one entry per postcard.</li>
        <li>Limit one entry per person per Contest Week.</li>
      </ul>
      <li>
        <h3>Selection of Winner.</h3>
      </li>
      <ul>
        <li>
          One (1) Participant who completes a valid entry into the Contest will be selected at
          random each Contest Week to each receive two (2) courtside tickets to a Cleveland
          Cavaliers home game (“Cavaliers Ticket Prize”);
        </li>
        <li>
          Two (2) Participants who complete valid entries into the Contest will be selected at
          random each Contest week to each receive a Cleveland Cavaliers jersey (“Cavaliers
          Merchandise Prize”);
        </li>
        <li>
          The winner(s) for the Contest will be notified by email within five (5) business days
          following the selection of the winner(s). The notification will include instructions for
          claiming the tickets or merchandise. Prize(s) must be claimed within one (1) week of
          notification.
        </li>
        <li>
          Prize is listed in the table below. Subject to verification of eligibility and compliance
          with the terms of these Rules, the potential winner will be declared the official winner
          of the Contest. Eligibility determinations shall be in Sponsor's sole discretion. Odds of
          winning depend on the total number of eligible participants. The purchase of any goods or
          services will not increase any participant's chances of winning. All decisions of the
          Sponsor regarding the Contest and the application of these Rules are in Sponsor's sole
          discretion, final, and binding.
        </li>
        <li>
          In the event that any potential winner forfeits or is disqualified for any reason, the
          Prize may be reallocated to another participant in the sole discretion of the Sponsor.
        </li>
        <li>
          The winner is solely responsible for all federal, state, and local taxes and other fees
          associated with his or her receipt and use of a Prize, including but limited to any
          travel, transportation, parking, food, and beverage costs. No substitution, assignment or
          transfer of Prize is permitted, except by the Sponsor, who reserves the right to
          substitute the Prize with another prize of greater or equal value if Prize cannot be
          awarded as stated. The Sponsor will withhold and/or report taxes as required by the
          applicable law of the Sponsor's jurisdiction, and winners must provide accurate address
          and social security details to Sponsor to allow Sponsor to comply with tax regulations and
          share with appropriate tax authorities.
        </li>
        <li>
          Sponsor is responsible only for prize delivery, which may be limited to electronic
          transfer of tickets to winner(s), and expressly disclaims responsibility for prize
          utility, quality, or otherwise. Sponsor does not provide tax advice, nor should any
          statements in this agreement be construed as tax advice.
        </li>
        <li>
          Winning any contest, sweepstakes or free-to-play game (each an “FTP Game”) sponsored by
          Fubo or its affiliates may disqualify you from winning another FTP Game within 90 days
          thereafter.
        </li>
      </ul>
      <li>
        <h3>Prizes and Odds:</h3>Odds of winning depend on the number of eligible entries received
        during the applicable Contest Date.
        <div>
          <table>
            <tbody>
              <tr>
                <th>Prize</th>

                <th>No. Winners of Each Prize</th>

                <th>Prize Description</th>

                <th>Approximate Retail Value</th>
              </tr>
              <tr>
                <td>Cleveland Cavaliers Ticket Prize</td>
                <td>One (1) per Contest Week; Ten (10) total winners for Contest</td>
                <td>
                  Each winner will receive two (2) courtside tickets to a home game of the Cleveland
                  Cavaliers – game date(s) and seat location(s) shall be selected by Sponsor
                </td>
                <td>$1,000 per ticket</td>
              </tr>
              <tr>
                <td>Cavaliers Merchandise Credit</td>
                <td>Two (2) per Contest Week; Twenty (20) total winners for Contest</td>
                <td>
                  Each winner will receive a choice of one (1) Cleveland Cavaliers jersey from a
                  selection of jerseys made available by Sponsor
                </td>
                <td>$100 per jersey</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
      <li>
        ALL PRIZES ARE AWARDED AS IS AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE. SOME JURISDICTIONS MAY NOT ALLOW (OR MAY LIMIT) DISCLAIMERS OF CERTAIN
        WARRANTIES, IN WHICH CASE THE FOREGOING DISCLAIMERS WILL BE ENFORCED TO THE MAXIMUM EXTENT
        PERMITTED BY LAW.
      </li>
      <li>
        <h3>Applicable Rules and Winner's List:</h3>For any legally required winner's list, send a
        self-addressed stamped envelope (unless otherwise prohibited by local law) to fuboTV Media
        Inc., 1290 Avenue of the Americas, New York, NY 10104, Attention: Fubo Ultimate Cavs Fan
        Sweepstakes. All such requests must be received within three (3) months after the end of the
        Contest.
      </li>
      <li>
        <h3>No Third-Party Sponsor.</h3>The Contests are in no way sponsored, endorsed, or
        administered by, or in association with any person or other entity, including any third
        party, other than Sponsor.
      </li>
      <ul>
        <li>
          LinkedIn, Facebook, Twitter, and YouTube, collectively “Social Media Sites” are not
          sponsors, endorsers, or affiliated in any way with the Contest. All questions regarding
          the Contests must be directed to Sponsor, not any social media site. You agree as a
          condition of participating in the Contest that you shall release Social Media Sites from
          any and all liability arising out of or relating to your entry, creation of an entry,
          submission of an entry, participation in the sweepstakes, acceptance, use, or misuse of
          any prize, or the broadcast, exploitation, or use of an entry.
        </li>
        <li>
          Bally Sports and Sinclair Broadcast Group, collectively “Networks” are not sponsors,
          endorsers, or affiliated in any way with the Contest. You agree as a condition of
          participating in the Contests that you shall release Networks from any and all liability
          arising out of or relating to your entry, creation of an entry, submission of an entry,
          participation in the sweepstakes, acceptance, use, or misuse of any prize, or the
          broadcast, exploitation, or use of an entry.
        </li>
        <li>
          The Cleveland Cavaliers and their affiliated companies, collectively “Cavaliers” are not
          sponsors or endorsers of with the Contest. You agree as a condition of participating in
          the Contest that you shall release Cavaliers from any and all liability arising out of or
          relating to your entry, creation of an entry, submission of an entry, participation in the
          sweepstakes, acceptance, use, or misuse of any prize, or the broadcast, exploitation, or
          use of an entry.
        </li>
      </ul>
      <li>
        <h3>Publicity.</h3>Prize winners agree that their names and location may be used for
        marketing purposes. By participating in the Contest, the Winner(s) agree(s) to refrain from
        any behavior or communication that would harm the image or reputation of Sponsor or the
        community of subscribers to Sponsor's products. YOU FURTHER ACKNOWLEDGE AND AGREE THAT YOUR
        PERSONAL IDENTIFYING INFORMATION MAY BE DISCLOSED TO THIRD PARTIES INCLUDING, WITHOUT
        LIMITATION, PLACING YOUR NAME ON A WINNER'S LIST.
      </li>
      <li>
        <h3>Additional Terms and Conditions.</h3>The decisions made by Fubo management regarding,
        but not limited to, eligibility, winners, scoring, rules, prizes and compliance with Fubo's
        standards of conduct shall be final. No correspondence will be entered into. Fubo reserves
        the right to make reasonable changes to these terms, (a) to ensure the fairness of
        competition, and (b) in response to unanticipated developments, such as, but not limited to,
        technical failures, errors, acts of God, natural disasters, terrorism, and changes in
        applicable law.
      </li>
      <li>
        <h3>Choice of Law and Disputes.</h3>Disputes shall be subject to the provisions in Sponsor's
        terms of service, which are hereby incorporated by reference.
      </li>
      <li>
        <h3>Conditions of Participation and Limitations of Liability.</h3>By participating, entrants
        hereby release, hold harmless, covenant not to sue, and forever discharge Sponsor, its
        respective parent companies, affiliates, subsidiaries, retailers, divisions, dealers,
        advertising and promotion agencies, and all others associated with the development and
        execution of the Contest, and each of its and their directors, employees, officers, and
        agents from and against any and all third-party claims, actions, damages, losses,
        liabilities, costs, expenses, injuries, or causes of action of any kind that in any way now
        or hereinafter may arise from or relate to the Contest, including without limitation (i) the
        administration of the Contest, such as typographical, printing, seeding, human or other
        errors relating to or in connection with the Contest, the processing of entries, the
        announcement of the prize or any related materials, inaccurate, fraudulent, incomplete,
        illegible, late, lost, stolen, misdirected, undelivered, incomplete, or damaged entries, any
        delays in delivery or lack of availability of the prize or prize materials and/or (ii)
        entrants' participation in the Contest offered by Sponsor or their acceptance or use of a
        prize, or participation in any prize related activities, including, without limitation, any
        travel related thereto, and death and bodily injury (including emotional distress), due in
        whole or in part, directly or indirectly, to participation in the Contests or any
        Contests-related activity and for any claims or causes of action based on publicity rights,
        defamation, or invasion of privacy. liability, loss or damages arising from or in connection
        with the awarding, receipt, and/or use or misuse of any prize or participation in any
        prize-related activities. Sponsor reserves the right at its sole discretion to disqualify
        any individual who is found to be (i) acting in violation of these Rules or (ii) acting in
        an unsportsmanlike or disruptive manner, with the intent to disrupt or undermine the
        legitimate operation of the Contest, or with the intent to annoy, abuse, threaten or harass
        any other person. Without limiting the foregoing, in the event Sponsor is prevented from
        continuing with the Contest as contemplated by any event beyond its control, including,
        without limitation, a force majeure, fire, flood, epidemic or other national health
        emergency, earthquake, explosion, labor dispute or strike, act of God or public enemy,
        satellite or equipment failure, riot or civil disturbance, terrorist threat or activity, war
        (declared or undeclared,) or any federal, state or local government law, order, or
        regulation, order of any court or jurisdiction, public health crisis, or other cause not
        reasonably within its control, then Sponsor shall also have the right to modify, suspend,
        extend or terminate the Contest. In the event that the Contest is terminated, canceled, or
        postponed for any reason whatsoever, the ARV of the prize(s) will be awarded to the extent
        required by law.
      </li>
      <li>
        <h3>Gender Neutral Language.</h3>
        Words of any gender used in these Rules shall be held and construed to include any other
        gender, and words in the singular number shall be held to include the plural, unless the
        context otherwise requires.
      </li>
    </ol>
    <p>
      <strong>
        CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE SPONSOR'S SERVICE OR UNDERMINE THE LEGITIMATE
        OPERATION OF THE CONTESTS OFFERED BY SPONSOR IS A VIOLATION OF CRIMINAL AND CIVIL LAWS.
        SHOULD SUCH AN ATTEMPT BE MADE, SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES FROM ANY SUCH
        INDIVIDUAL TO THE FULLEST EXTENT PERMITTED BY LAW.
      </strong>
    </p>
  </TermsConditionsTemplate>
)
