import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      offerCode="pro-florida-panthers-14d-trial"
      offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
      video={videos.bally_panthers.videoUrl}
      staticAnnotation={videos.bally_panthers.staticAnnotation}
      pageTitle="Watch Florida Panthers hockey"
      title={<>Watch Florida Panthers hockey</>}
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream Bally Sports from any device. No cable required."
      networkSet="hockey"
      customNetworks={['ballysportsflorida']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-florida-panthers.png?ch=width&auto=format,compress"
      logoHeight="100px"
    />
  )
}
