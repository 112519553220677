import { Link } from 'gatsby'
import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default props => (
  <StyledContainer>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerCode="pro-cleveland-cavaliers-14d-trial"
      offerBanner={
        <>
          Here's your chance to win tickets to a Cavs game!
          <br />
          Join Fubo today to qualify for entry into the Fubo Ultimate Cavs Fan Sweepstakes.*
        </>
      }
      video={videos.bally_cavaliers.videoUrl}
      staticAnnotation={videos.bally_cavaliers.staticAnnotation}
      title="Watch the Cleveland Cavaliers live"
      subtitle="STREAM THE CAVS ON BALLY SPORTS OHIO"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      heroCtaNote={
        <>
          *No purchase necessary. Open to subscribers of a paid English language Fubo subscription
          plan that includes paid access to the regional sports network Bally Sports Ohio or through
          write-in entry. The Fubo Ultimate Cavs Fan Sweepstakes begins 2/1/24 at 9:00 am ET and
          ends 8:59:59 am ET on 4/11/24. Must be 18 or older. Void where prohibited. Terms and
          Conditions apply. For Official Rules and ways to enter, visit{' '}
          <Link to="/sweepstakes/cavs/" style={{ textDecoration: 'underline' }}>
            here
          </Link>
          .
        </>
      }
      networkSet="basketball"
      customNetworks={['ballysportsohio']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-cavs-lockup.png?w=450&ch=width&auto=format,compress"
    />
  </StyledContainer>
)
const StyledContainer = styled.div`
  .content-container .hero-note {
    font-size: 0.9rem;
    font-style: italic;
  }
`
