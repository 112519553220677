import React from 'react'
import LatinoTemplateDefault from 'src/components/template/us/'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/mls-espn-deportes-22sec.mp4"
      title="Watch MLS on ESPN Deportes"
      subtitle="LIVE MATCHES, LEAGUE ANALYSIS AND MORE"
      tagline="Stream it from your TV, phone and other devices"
      networkSet="soccer"
      customNetworks={['espndeportes']}
    />
  )
}
