import React from "react"
import BallySportsNHL from "src/components/page/bally-sports-nhl"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_kings.videoUrl}
    staticAnnotation={videos.bally_kings.staticAnnotation}
    title="Watch Los Angeles hockey live"
    subtitle="STREAM THE KINGS ON BALLY SPORTS WEST"
    customNetworks={["ballysportswest"]}
  />
)