import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.baltimore_orioles.videoUrl}
    staticAnnotation={videos.baltimore_orioles.staticAnnotation}
    offerCode="pro-affiliation-15-off"
    offerBanner={
      <>
        ✔ Special offer unlocked! <br />
        Orioles fans get 15% off their first month.
      </>
    }
    pageTitle="Watch Baltimore Orioles baseball"
    title={
      <>
        Watch Baltimore Orioles<sup>®</sup> baseball
      </>
    }
    subtitle="GET LIVE GAMES, TEAM NEWS & MORE"
    tagline="Stream it all on MASN from any device"
    networkSet="baseball"
    league="mlb"
    customNetworks={['masn']}
  />
)
