import React from 'react'
import { useSelector } from 'react-redux'
import CTAButton from 'src/components/common/PlanCardCTA'
import { track } from 'src/modules/analytics/track'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import { selectCTAParams, selectParams } from 'src/modules/reducers/params'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import styled from 'styled-components'

const PlanCardWithImage = ({
  promoImg,
  plan,
  length,
  title,
  subtitle,
  price,
  discountedPrice,
  savingsNote,
  // listItems will need to be an array of objects of {string, boolean}
  listItems,
  offerCode,
  ctaUrl,
  ctaText = 'Get Access',
  footnote,
  onClick,
  btnColor,
  banner,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  ctaURL = replaceUrlParam(ctaURL, 'offer', offerCode)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'plan-card')

  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const handleOnClick = item => {
    const eventProperties = {
      ...analyticsProperties,
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'cta_plan_card',
        offer_slug: offerCode,
      },
    }
    track('lp_interaction', eventProperties)
  }

  return (
    <StyledPlanCardWithImage>
      <a className="planCard" href={ctaURL} onClick={handleOnClick}>
        {banner && <div className="planCard__banner">{banner}</div>}
        <div className="planCard__image">
          <img src={promoImg} alt={title} />
        </div>
        <div className="planCard__content">
          <div className="planCard__header">
            <div className="planCard__title">
              {title} {length}
            </div>
            <div className="planCard__subtitle">{subtitle}</div>
            <div className="planCard__divider"></div>
            <div className="planCard__price">
              {discountedPrice ? (
                <>
                  {discountedPrice}
                  {footnote && <span className="asterisk">*</span>}{' '}
                  <span className="faded-price">{price}</span>
                </>
              ) : (
                <>
                  {price}
                  {footnote && <span className="asterisk">*</span>}
                </>
              )}
            </div>
            {savingsNote && <div className="discount__note">{savingsNote}</div>}
          </div>
          <div className="planCard__list">
            {listItems?.map((item, index) => {
              if (item === null) return null
              const check = item.available ? (
                <span className="check">✔&#xfe0e;</span>
              ) : (
                <span className="cross">✘&#xfe0e;</span>
              )

              return (
                <div key={index} className="list-item">
                  {check} {item.text}
                </div>
              )
            })}
          </div>
        </div>
        <div className="planCard_cta">
          <CTAButton link={ctaUrl} color={btnColor && '#ffffff'}>
            {ctaText}
          </CTAButton>
        </div>
      </a>
      <div className="planCard__footnote">{footnote}</div>
    </StyledPlanCardWithImage>
  )
}

export default PlanCardWithImage

const StyledPlanCardWithImage = styled.div`
  width: 100%;
  max-width: 500px;
  .planCard {
    ${'' /* width: 460px; */}
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding-top: 52%;
    padding-bottom: 10%;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    background: #0e0330;
    cursor: pointer;
    transition: transform 0.4s;
    &:hover {
      transform: scale(1.01);
    }
    @media (max-width: 992px) {
      padding-bottom: 8%;
    }
    .planCard__banner {
      z-index: 3;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      background: rgba(0, 136, 23, 0.98);
      color: #fff;
      padding: 6px 10px;
      font-weight: 700;
      font-size: 1.4rem;
    }
    .planCard__image {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      img {
        width: 100%;
      }
      &::after {
        background: radial-gradient(ellipse 103% 92% at top right, transparent 60%, #0e0330);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .planCard__content {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 8%;
      padding-right: 8%;
      .planCard__header {
        .planCard__title {
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .planCard__subtitle {
          font-size: 20px;
          font-weight: 500;
        }
        .planCard__divider {
          width: 50px;
          height: 1px;
          background: #fff;
          margin: 24px 0;
        }
        .planCard__price {
          font-size: 26px;
          font-weight: 700;
          @media (max-width: 992px) {
            font-size: 20px;
          }
        }
        .faded-price {
          font-size: 0.7em;
          color: rgba(255, 255, 255, 0.8);
          text-decoration: line-through;
          font-weight: 600;
          -webkit-text-decoration-color: #fa4616;
          text-decoration-color: #fa4616;
        }
        .discount__note {
          font-size: 0.75rem;
          font-weight: 600;
          letter-spacing: 0.01em;
          line-height: 1.25;
          text-decoration: none;
          text-transform: none;
          color: rgb(255, 255, 255);
          display: inline-block;
          background-color: rgb(0, 136, 23);
          border-radius: 4px;
          padding: 4px 8px;
        }
      }
      .planCard__list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        @media (max-width: 992px) {
          padding-bottom: 20px;
        }
        .list-item {
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0px;
          margin-bottom: 14px;
          display: flex;
          span {
            width: 20px;
            margin-right: 8px;
            line-height: 1;
            flex: 0 0 20px;
          }
          .check {
            color: #04a928;
            font-size: 18px;
          }
          .cross {
            color: #ff0000;
            font-size: 20px;
          }
        }
      }
    }
    .planCard_cta {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: calc(11%);
      padding-right: calc(11%);
      height: 100%;
      justify-content: flex-end;
      text-transform: uppercase;
    }
  }
  .planCard__footnote {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
  }
`
