import React from "react"
import BallySportsMLB from "src/components/page/bally-sports-mlb"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_rays.videoUrl}
    staticAnnotation={videos.bally_rays.staticAnnotation}
    title="Watch Tampa Bay baseball live"
    subtitle="STREAM THE RAYS ON BALLY SPORTS SUN"
    customNetworks={["ballysportssun"]}
  />
)