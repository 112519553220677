import React from 'react'
import { useSelector } from 'react-redux'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectParams } from 'src/modules/reducers/params'
export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      video={videos.bally_cavaliers.videoUrl}
      staticAnnotation={videos.bally_cavaliers.staticAnnotation}
      title="Watch the Cleveland Cavaliers live"
      subtitle="STREAM THE CAVS ON BALLY SPORTS OHIO"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      networkSet="basketball"
      customNetworks={['ballysportsohio']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-cavs-lockup.png?w=450&ch=width&auto=format,compress"
    />
  )
}
