import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.generic_soccer.videoUrl}
      title="Watch El Clásico online"
      subtitle="REAL MADRID VS BARCELONA LIVE"
      tagline="Stream the game and more from your phone, TV and other devices"
      networkSet="soccerLatino"
      noteLink="/la/clasico-es/"
    />
  )
}
