import React from 'react'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_coyotes.videoUrl}
    // staticAnnotation={videos.bally_coyotes.staticAnnotation}
    title="Watch Arizona hockey live"
    subtitle="STREAM THE COYOTES ON ARIZONA 61"
    // customNetworks={["ballysportsarizona"]}
  />
)
