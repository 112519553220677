import React from "react"
import BallySportsNHL from "src/components/page/bally-sports-nhl"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_redwings.videoUrl}
    staticAnnotation={videos.bally_redwings.staticAnnotation}
    title="Watch Detroit hockey live"
    subtitle="STREAM THE RED WINGS ON BALLY SPORTS DETROIT"
    customNetworks={["ballysportsdetroit"]}
  />
)