import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_diamondbacks.videoUrl}
    title="Watch Arizona baseball live"
    subtitle="STREAM DIAMONDBACKS GAMES ALL SEASON LONG"
  />
)
