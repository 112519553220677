import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.generic_sports_no_football_no_golf.videoUrl}
    title="Watch Bally Sports on Fubo"
    subtitle="STREAM LIVE GAMES AND LOCAL TEAM COVERAGE"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="sports"
    customNetworks={['ballysports']}
  />
)
