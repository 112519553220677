import axios from 'axios'

// const rsnDict = {
//   'attsn pt': 'AT&T Sportsnet Pittsburgh',
//   'attsn rm': 'AT&T Sportsnet Rocky Mountain',
//   'attsn sw': 'Space City Home Network',
//   altitude: 'Altitude Sports',
//   'bally sports': 'Bally Sports',
//   'bs arizona': 'Bally Sports Arizona',
//   'bs detroit': 'Bally Sports Detroit',
//   'bs florida': 'Bally Sports Florida',
//   'bs great lakes': 'Bally Sports Great Lakes',
//   'bs midwest': 'Bally Sports Midwest',
//   'bs north': 'Bally Sports North',
//   'bs ohio': 'Bally Sports Ohio',
//   'bs san diego': 'Bally Sports San Diego',
//   'bs socal': 'Bally Sports SoCal',
//   'bs south': 'Bally Sports South',
//   'bs southeast': 'Bally Sports Southeast',
//   'bs southwest': 'Bally Sports Southwest',
//   'bs sun': 'Bally Sports Sun',
//   'bs west': 'Bally Sports West',
//   msg: 'MSG',
//   marquee: 'Marquee',
//   'nbcs ba': 'NBC Sports Bay Area',
//   'nbcs boston': 'NBC Sports Boston',
//   'nbcs ca': 'NBC Sports California',
//   'nbcs chicago': 'NBC Sports Chicago',
//   'nbcs philly': 'NBC Sports Philadelphia',
//   'nbcs wash': 'NBC Sports Washington',
//   nesn: 'NESN',
//   'root nw': 'ROOT SPORTS Northwest',
//   sny: 'SportsNet NY',
// }

// export const convertRSNNames = data => {
//   return data.map(rsn => {
//     const rsnLC = rsn.toLowerCase()
//     return rsnDict[rsnLC] || rsn
//   })
// }

export const fetchMatches = async (sportId, leagueId, postal) => {
  if (postal === undefined) return
  const baseUrl = `https://api.fubo.tv/matches/find/v2`
  let queriesList = {}

  if (sportId) {
    queriesList.sportId = sportId
  }
  if (leagueId) {
    queriesList.leagueId = leagueId
  }
  if (postal) {
    queriesList.postal = postal
  }

  // take queriesList and format it for URL
  const urlQueries = new URLSearchParams(queriesList).toString()

  const fetchUrl = `${baseUrl}/${urlQueries && '?' + urlQueries}`
  // console.log('fetching', fetchUrl)
  const res = await axios.get(fetchUrl)

  // // Get the current timestamp
  // const currentTimestamp = Date.now()

  // // Filter out past events and events without teamsMetadata, then sort upcoming events by start time
  // const validMatches = resResult
  //   .filter(match => {
  //     // Check if the match has teamsMetadata and a valid start time
  //     const teamsMetadata = match.program?.metadata?.teamsMetadata
  //     const matchTime = match.assets[0]?.accessRights?.endTime
  //     const matchDate = new Date(matchTime)
  //     return teamsMetadata && matchDate && matchDate > currentTimestamp
  //   })
  //   .sort((matchA, matchB) => {
  //     // Compare the start times of the matches for sorting
  //     const startTimeA = new Date(matchA.assets[0]?.accessRights?.startTime) || 0
  //     const startTimeB = new Date(matchB.assets[0]?.accessRights?.startTime) || 0
  //     return startTimeA - startTimeB
  //   })

  // validMatches now contains only upcoming matches with teamsMetadata, sorted by start time
  return res.data.response
}
