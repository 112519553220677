import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_marlins.videoUrl}
    staticAnnotation={videos.bally_marlins.staticAnnotation}
    title="Watch Miami baseball live"
    subtitle="STREAM THE MARLINS ON BALLY SPORTS FLORIDA"
    customNetworks={['ballysportsflorida']}
  />
)
