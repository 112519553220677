import React, { useState, useEffect } from 'react'
// Imports for A/B Testing
import { useExperiment, emitter, experimentDebugger } from '@marvelapp/react-ab-test'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAnalyticsProperties,
  selectAnalyticsPropertiesReady,
} from 'src/modules/reducers/analytics'
import { updateParams } from 'src/modules/reducers/params'
import { track } from 'src/modules/analytics/track'
import { updateExperiments } from '../reducers/experiments'
// End A/B Testing Code

// Add this to component file outside of component

// import { emitter } from '@marvelapp/react-ab-test'
// const abName = 'MLB_RSN_Redirect'
// let definedVariants = [abName + '-control', abName + '-treatment']
// emitter.defineVariants(abName, definedVariants)
// emitter.calculateActiveVariant(abName)
// let variants = {
//   [`${abName}-control`]: false,
//   [`${abName}-treatment`]: true,
// }

// const variant = useABTest(abName, variants)

experimentDebugger.enable()

export const useABTest = (name, variants: array, disabled, noEvent) => {
  const dispatch = useDispatch()
  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const abtestParam = {
    ab_campaign: name,
  }

  useEffect(() => {
    dispatch(updateParams(abtestParam))
  }, [])

  const { selectVariant } = useExperiment(name)

  const chosenVariant = selectVariant(variants)

  const analyticsPropertiesReady = useSelector(selectAnalyticsPropertiesReady)

  // sent analytics
  const [sentEvent, setSentEvent] = useState(false)

  useEffect(() => {
    if (chosenVariant !== undefined) {
      dispatch(updateExperiments({ [`${name}`]: chosenVariant }))
    }
  }, [chosenVariant])

  useEffect(() => {
    if (
      chosenVariant !== undefined &&
      analyticsPropertiesReady &&
      !sentEvent &&
      !disabled &&
      !noEvent
    ) {
      const experimentProperties = {
        experiment_id: name,
        variation_id: emitter.getActiveVariant(name),
      }
      const eventProperties = {
        ...analyticsProperties,
        ...experimentProperties,
      }
      track('experiment_viewed', eventProperties)
      setSentEvent(true)
    }
  }, [chosenVariant, analyticsPropertiesReady])

  if (disabled) {
    return null
  }
  return chosenVariant
}
