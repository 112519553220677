import React from 'react'
import Checkmark from 'src/components/template/compare/components/icons/Checkmark'
import Crossmark from 'src/components/template/compare/components/icons/Crossmark'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const CompareTable = ({ competitor, competitorColor, tableData: data }) => {
  return (
    <StyledCompareTable competitorColor={competitorColor}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th className="logo">
              <img
                src="https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_FC_RGB.png?w=300&ch=width&auto=format,compress"
                alt="Fubo"
              />
            </th>
            <th className="logo">{competitor}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, values], index) => {
            return (
              <tr key={index}>
                <td>{key}</td>
                {values.map((value, index) => {
                  const item =
                    typeof value === 'boolean' ? (
                      value ? (
                        <span className="check">
                          <Checkmark />
                        </span>
                      ) : (
                        <span className="cross">
                          <Crossmark />
                        </span>
                      )
                    ) : (
                      value
                    )
                  return <td key={index}>{item}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </StyledCompareTable>
  )
}

export default CompareTable

const StyledCompareTable = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
  }

  th {
    padding: 8px;

    --logo-height: 15px;

    ${breakpointQuery.desktop} {
      --logo-height: 33px;
    }

    &.logo {
      width: 100%;
      font-family: 'Qanelas';
      font-style: normal;
      font-weight: 800;
      font-size: var(--logo-height);
      line-height: 1.5;
      /* identical to box height, or 159% */

      color: ${props => props.competitorColor || '#ffffff'};
      img {
        height: var(--logo-height);
      }
    }
  }

  tbody {
    flex: 1;
  }

  tr {
    display: flex;
    width: 100%;
  }

  th {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  td {
    padding: 8px;
    border-bottom: 2px solid #19273c;
    border-right: 2px solid #19273c;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Qanelas';
    font-style: normal;
    font-weight: 700;
    font-size: 8.06418px;
    line-height: 13px;
    /* identical to box height, or 158% */

    text-align: center;

    /* _/White */

    color: #ffffff;

    ${breakpointQuery.desktop} {
      padding: 28px;

      font-family: 'Qanelas';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 158% */

      text-align: center;
    }
  }

  td:first-child {
    justify-content: flex-start;

    font-family: 'Qanelas';
    font-style: normal;
    font-weight: 500;
    font-size: 7.16816px;
    line-height: 13px;
    /* identical to box height, or 177% */

    ${breakpointQuery.desktop} {
      font-family: 'Qanelas';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 177% */
    }
  }

  td:last-child {
    border-right: none;
  }

  .check {
    display: inline-block;
    width: 10px;

    ${breakpointQuery.desktop} {
      width: 17px;
    }
  }

  .cross {
    display: inline-block;
    width: 9px;

    ${breakpointQuery.desktop} {
      width: 20px;
    }
  }
`
