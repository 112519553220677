import React from 'react'
import USTemplateDefault from 'src/components/template/us'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="es"
    market="us"
    video={
      'https://assets.fubo.tv/marketing/lp/videos/compressed/ES_WorldCup2022_1280x720_LP_10s.mp4'
    }
    staticAnnotation={{
      logoUrl:
        'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/world_cup_qatar_2022_telemundo.png?h=120&ch=width&auto=format,compress',
      height: 80,
    }}
    title="Ve la Copa Mundial de la FIFA 2022™"
    subtitle="TODOS LOS PARTIDOS EN VIVO POR TELEMUNDO"
    tagline="Disponible desde tu teléfono, computador, Apple TV, Roku, FireTV y más"
    networkHeadline="Ve la Copa Mundial de la FIFA"
    networkSet="soccerLatino"
    customNetworks={['telemundo', 'universo', 'fs1']}
  />
)
