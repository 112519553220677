import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.copa_america_us.videoUrl}
    staticAnnotation={videos.copa_america_us.staticAnnotation}
    title="Watch the 2024 Copa América"
    subtitle="CATCH ALL THE ACTION FROM EVERY MATCH"
    tagline="Stream it live on FOX & FS1 from any device with Fubo"
    networkSet="soccer"
    customNetworks={['fox', 'fs1']}
  />
)
