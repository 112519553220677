import { emitter } from '@marvelapp/react-ab-test'
import React, { useEffect, useState } from 'react'
import { useABTest } from 'src/modules/hooks/useABTest'
import BundlePlansCAByPlan from '.'
import BundlePlansCAControlVariant from '../BundlePlansCAControlVarient '

const abName = 'CA_Plan_Bundles'
let definedVariants = [abName + '-sorted-by-plan-duration', abName + '-sorted-by-plan-type']

emitter.defineVariants(abName, definedVariants)
emitter.calculateActiveVariant(abName)
let variants = {
  [definedVariants[0]]: definedVariants[0],
  [definedVariants[1]]: definedVariants[1],
}

const TestBundlePlansCAByPlans = ({
  plan,
  offerCode,
  soccerPlanCardImage,
  premiumPlanCardImage,
  ctaText,
  forcePlanLength,
  planCardOverride,
  plansTitle,
  plansSubtitle,
  preselectPlanLength,
  mlbtvOffer,
}) => {
  const [testVariant, setTestVariant] = useState(null)
  const variant = useABTest(abName, variants)

  useEffect(() => {
    if (typeof variant !== 'undefined' && variant !== null) {
      setTestVariant(variant)
    }
  }, [variant])
  if (testVariant == definedVariants[1]) {
    return (
      <BundlePlansCAByPlan
        plan={plan}
        title={plansTitle}
        subtitle={plansSubtitle}
        offerCode={offerCode}
        soccerPlanCardImage={soccerPlanCardImage}
        premiumPlanCardImage={premiumPlanCardImage}
        ctaText={ctaText}
        forcePlanLength={forcePlanLength}
        planCardOverride={planCardOverride}
      />
    )
  } else if (testVariant == definedVariants[0]) {
    return (
      <BundlePlansCAControlVariant
        plan={plan}
        title={plansTitle}
        subtitle={plansSubtitle}
        offerCode={offerCode}
        soccerPlanCardImage={soccerPlanCardImage}
        premiumPlanCardImage={premiumPlanCardImage}
        ctaText={ctaText}
        preselectPlanLength={preselectPlanLength}
        forcePlanLength={forcePlanLength}
        planCardOverride={planCardOverride}
        mlbtvOffer={mlbtvOffer}
      />
    )
  } else {
    return null
  }
}

export default TestBundlePlansCAByPlans
