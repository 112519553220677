import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_pelicans.videoUrl}
    staticAnnotation={videos.bally_pelicans.staticAnnotation}
    title="Watch New Orleans basketball live"
    subtitle="STREAM THE PELICANS ON BALLY SPORTS NEW ORLEANS"
    customNetworks={['ballysportsneworleans']}
  />
)
