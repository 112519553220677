import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const DealSection = ({
  market = 'us',
  lang = 'en',
  dvrHours,
  background,
  title,
  savingsText,
  dvrIcon,
  dvrHeader,
  dvrText,
  cableSource,
}) => {
  const [isDesktop, setIsDesktop] = useState(false)

  const checkIsDesktop = () => {
    return window.innerWidth >= breakpoint
  }

  React.useEffect(() => {
    function handleResize() {
      setIsDesktop(checkIsDesktop())
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // GSAP animation
  gsap.registerPlugin(useGSAP, ScrollTrigger)

  const gsapContainer = useRef()

  useGSAP(
    () => {
      let mm = gsap.matchMedia()

      mm.add(`(min-width: ${desktopBreak})`, () => {
        const lines = gsap.utils.toArray('.deal-item')
        lines.forEach(line => {
          gsap.from(line, {
            opacity: 0,
            y: 120,
            scrollTrigger: {
              trigger: line,
              start: 'top bottom',
              end: 'top 40%',
              scrub: true,
              // markers: true,
            },
          })
        })
        return () => {
          // custom cleanup code here (runs when it STOPS matching)
        }
      })

      mm.add(`(max-width: ${mobileBreak})`, () => {
        const lines = gsap.utils.toArray('.deal-item')
        lines.forEach(line => {
          gsap.from(line, {
            opacity: 0,
            y: 50,
            scrollTrigger: {
              trigger: line,
              start: 'top bottom',
              end: 'top 40%',
              scrub: true,
              // markers: true,
            },
          })
        })
        return () => {
          // custom cleanup code here (runs when it STOPS matching)
        }
      })
    },
    { dependencies: [isDesktop], scope: gsapContainer, revertOnUpdate: true }
  ) // <-- scope is for selector text

  return (
    <StyledDealSection ref={gsapContainer}>
      <div className="deal-header">
        <h2>How is Fubo better than cable TV?</h2>
      </div>
      <div className="deal-content">
        <div className="deal-item">More savings.</div>
        {isDesktop ? (
          <div className="deal-item">No contracts, cancel anytime.</div>
        ) : (
          <>
            <div className="deal-item">No contracts.</div>
            <div className="deal-item">Cancel anytime.</div>
          </>
        )}
        <div className="deal-item">Cloud DVR included.</div>
      </div>
    </StyledDealSection>
  )
}

export default DealSection

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

// 3:2 Figma to Mobile (calculate 2/3 of figma sizing for mobile)

const StyledDealSection = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #161822;
  overflow: hidden;

  .deal-header {
    margin: 40px 20px;
    text-align: center;
    font-family: 'Qanelas', sans-serif;

    @media (min-width: ${desktopBreak}) {
      margin: 86px 20px 65px;
    }

    h2 {
      color: #fff;
      text-align: center;
      font-family: Qanelas;
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 1.1;

      @media (min-width: ${desktopBreak}) {
        font-size: 38px;
        max-width: none;
      }
    }
  }
  .deal-content {
    margin-bottom: 56px;
    @media (min-width: ${desktopBreak}) {
      margin-bottom: 84px;
    }
  }
  .deal-item {
    font-family: Qanelas;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 20px;

    background: linear-gradient(147deg, #fa8b6e 3.43%, #ff3a03 85.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: ${desktopBreak}) {
      font-size: 72px;
    }
  }
`
