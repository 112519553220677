import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { track } from 'src/modules/analytics/track'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import throttle from 'lodash/throttle'

function useTrackScroll() {
  const analyticsProperties = useSelector(selectAnalyticsProperties)
  // Ref to keep track of whether the event has been fired
  const hasTrackedRef = useRef(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY // Number of pixels scrolled vertically
      const windowHeight = window.innerHeight // Browser window height
      const scrolledPercentage = (scrollY / windowHeight) * 100

      if (scrolledPercentage > 75 && !hasTrackedRef.current) {
        hasTrackedRef.current = true // Set the ref to true to indicate the event has been tracked
        track('scrolled', analyticsProperties)
      }
    }

    // Throttle the scroll event handler for performance
    const throttledHandleScroll = throttle(handleScroll, 100)

    window.addEventListener('scroll', throttledHandleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [analyticsProperties]) // The dependency array ensures the effect uses the latest analyticsProperties
}

export default useTrackScroll
