import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerBanner="✔ Enter the code from your email to claim your $100 credit."
    hasOfferInput={true}
    video={videos.boston_bruins.videoUrl}
    pageTitle="Watch Boston Bruins® hockey"
    title={
      <>
        Watch Boston Bruins<sup>®</sup> hockey
      </>
    }
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream NESN from any device. No cable required."
    networkSet="hockey"
    customNetworks={['nesn']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-boston-bruins-v3.png?ch=width&auto=format,compress"
    logoHeight="100px"
  />
)
