import React from 'react'
import CollegeFootball from 'src/pages/college-football'
export default props => (
  <CollegeFootball
    {...props}
    team="Colorado Buffaloes"
    subtitle="SEE THE COLORADO BUFFALOES IN ACTION THIS SEASON"
    hasGenericVideo={true}
  />
)
