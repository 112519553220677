import React, { useEffect } from 'react'
import styled from 'styled-components'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { fetchData, fetchRSNs, listRegions } from 'src/modules/utils/checkRSN'
import { rsnData } from 'src/modules/utils/checkRSN/data/rsnData'
import { track } from 'src/modules/analytics/track'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import { selectPostalCode } from 'src/modules/reducers/market'
import CTAButton from 'src/components/common/CTAButtonNew'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const RSNGeolocationBanner = ({
  ctaHrefOverride,
  ctaQuery,
  hasOfferInput,
  ctaText = 'Start For Free',
  rsnOverride,
  hidden,
}) => {
  const postalCode = useSelector(selectPostalCode)
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const [eligibleRSN, setEligibleRSN] = React.useState(null)

  // init location check and set RSN eligibility
  React.useEffect(() => {
    if (postalCode) {
      fetchRSNs(postalCode)
        .then(rsnList => {
          if (rsnList) {
            setEligibleRSN(rsnList)
            // send show event to amplitude
            const eventProperties = {
              ...analyticsProperties,
              'ui_data.category': 'show',
              'ui_data.event_data': {
                component: 'show_rsn_only_banner',
                postal_code: postalCode,
                regions: rsnList,
              },
            }
            track('lp_event', eventProperties)
          }
        })
        .catch(error => console.error('Error fetching data:', error))
    }
  }, [postalCode])

  useEffect(() => {
    ScrollTrigger.refresh()
  }, [eligibleRSN, hidden])

  const handleOnClick = e => {
    const eventProperties = {
      ...analyticsProperties,
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'cta_rsn_geolocation_banner',
        content_name: eligibleRSN,
      },
    }
    track('lp_interaction', eventProperties)
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'rsnbanner')

  if (eligibleRSN && !hidden) {
    const filteredRSN = [
      ...new Set(eligibleRSN.map(channel => (channel.startsWith('BS') ? 'Bally Sports' : channel))),
    ]
    const RSNLogos = filteredRSN.map((rsn, i) => {
      const imgSrc = rsnData[rsn].logo + '?w=160&ch=width&auto=format,compress'

      return (
        <div className="banner-image" key={i}>
          <img src={imgSrc} />
        </div>
      )
    })

    return (
      <StyledRSNGeolocationBanner>
        <div className="banner-content">
          <div className="banner-logos">{RSNLogos}</div>
          <div className="banner-text">
            <h3>Fubo has your local teams live</h3>
          </div>
          <CTAButton link={hasOfferInput ? '#' : ctaHrefOverride || ctaURL} onClick={handleOnClick}>
            {/* {ctaText || 'Start For Free'} */}
            Start watching{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              // width="20"
              // height="20"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.2048 26.4798C20.5015 26.4798 26.4166 20.5647 26.4166 13.268C26.4166 5.97129 20.5015 0.0561523 13.2048 0.0561523C5.90806 0.0561523 -0.00708008 5.97129 -0.00708008 13.268C-0.00708008 20.5647 5.90806 26.4798 13.2048 26.4798ZM18.4417 13.7207C18.7987 13.5194 18.7987 13.0163 18.4417 12.815L9.60402 7.83377C9.24695 7.63251 8.8006 7.88409 8.8006 8.28661V18.2491C8.8006 18.6517 9.24695 18.9032 9.60402 18.702L18.4417 13.7207Z"
                fill="#CBCBE2"
              />
            </svg>
          </CTAButton>
        </div>
      </StyledRSNGeolocationBanner>
    )
  }
  return null
}

export default RSNGeolocationBanner

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

const StyledRSNGeolocationBanner = styled.div`
  background: linear-gradient(90deg, #2c323e 0.1%, #14171f 89.41%);
  width: 100%;
  z-index: 5;
  text-align: left;
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1460px;
    flex-direction: column;
    ${'' /* gap: 36px; */}
    padding: 38px 10px 44px;

    @media (min-width: ${desktopBreak}) {
      padding: 20px 70px;
      gap: 20px;
      flex-direction: row;
    }

    .banner-text {
      color: #fff;
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      text-align: center;
      padding: 0;
      margin-bottom: 33px;
      @media (min-width: ${desktopBreak}) {
        padding: 10px 10px;
        gap: 8px;
        order: 1;
        margin-bottom: 0;
        flex: 0 1 350px;
      }
      h3 {
        text-align: center;
        font-family: Qanelas;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.33; /* 133.333% */
        span {
          display: inline-block;
        }
        @media (min-width: ${desktopBreak}) {
          font-size: 24px;
        }
      }
    }
    .banner-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      max-width: 400px;
      gap: 20px;
      margin-bottom: 24px;

      @media screen and (min-width: ${desktopBreak}) {
        flex: 1 2 310px;
        gap: 20px;
        order: 2;
        margin-bottom: 0;
      }

      .banner-image {
        flex: 0 1 auto;
        object-fit: contain;
        max-width: 110px;
        display: inline-flex;
        @media screen and (min-width: ${desktopBreak}) {
          max-width: 135px;
          max-height: 45px;
        }
        img {
          object-fit: contain;
          max-width: 100%;
        }
      }
    }
    .CTAButton {
      background: #5d6479;
      font-size: 17px;
      @media screen and (min-width: ${desktopBreak}) {
        order: 3;
        font-size: 20px;
      }
      svg {
        width: 20px;
        height: 20px;
        vertical-align: bottom;
        @media screen and (min-width: ${desktopBreak}) {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
`
