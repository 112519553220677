import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/ATTSportsnet_RockyMountain.mp4'}
      title="Watch AT&T SportsNet live"
      subtitle="SEE THE JAZZ, GOLDEN KNIGHTS AND ROCKIES"
      tagline="Stream games from any device. Regional restrictions apply."
      customNetworks={['attsportsnet']}
    />
  )
}
