import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const HeroCTA = ({ link }) => {
  return (
    <ButtonWrapper className="heroCtaWrapper">
      <a href={link} target="_top">
        CONTINUE WATCHING ON FUBO
      </a>
    </ButtonWrapper>
  )
}

export default HeroCTA

const ButtonWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  font-family: 'Qanelas', sans-serif;
  background: #fa4616;
  height: 60px;

  a {
    display: block;
    font-size: 23px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    background: #fa4616;
    padding: 18px 20px;
    border-radius: 3px;
    font-weight: 700;
    font-family: 'Qanelas-Bold', sans-serif;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    text-transform: uppercase;

    :hover {
      background: #b33922;
      cursor: pointer;
    }

    @media screen and (max-width: 736px) {
      font-size: 20px;
    }
    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
  }
`
