import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    video={videos.seattle_mariners.videoUrl}
    staticAnnotation={videos.seattle_mariners.staticAnnotation}
    offerCode="fubo-affiliation-evergreen-14d-trial"
    offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
    title="Watch Seattle Mariners baseball"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream ROOT SPORTS Northwest from any device. Regional restrictions apply."
    networkSet="baseball"
    customNetworks={['rootsports']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-seattle-mariners.png?w=450&ch=width&auto=format,compress"
    logoHeight="105px"
  />
)
