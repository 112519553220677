import React from 'react'
import styled from 'styled-components'

import IPhoneAppImg from 'src/components/common/PlatformSection/images/androidAppImg'
import AndroidImg from 'src/components/common/PlatformSection/images/iPhoneAppImg'
import AmazonFireImg from 'src/components/common/PlatformSection/images/amazonFireImg'
import AppleTVImg from 'src/components/common/PlatformSection/images/appleTVImg'
import MoreImg from 'src/components/common/PlatformSection/images/moreEs'

import headerBgImg from 'src/assets/images/stars.png'

import { defaultCTAURI, LatinoESDefaultCtaText } from 'src/modules/utils/constants'

const PlatformSection = ({ ctaQuery, lang = 'en', ctaText }) => {
  const formattedCTAQuery = ctaQuery ? `${defaultCTAURI}?${ctaQuery}` : `${defaultCTAURI}`

  const headline =
    lang === 'en' ? 'Stream Live & On-Demand from Anywhere' : 'Streaming En Directo & A La Carta'
  const subheadline = lang === 'en' ? 'On your favorite devices' : 'Donde Quieras, Cuando Quieras.'
  let buttonCopy
  if (lang === 'en') {
    buttonCopy = ctaText === 'Learn More' ? 'Learn More' : 'Get Access'
  } else {
    buttonCopy = ctaText === 'Learn More' ? LatinoESDefaultCtaText : 'COMENZAR 7 DÍAS GRATIS'
  }
  const ctaSubtext = lang === 'en' ? 'Cancel online anytime.' : 'Cancela online cuando quieras.'

  return (
    <SectionElement>
      <SectionHeader>
        <h1>{headline}</h1>
        <h2>{subheadline}</h2>
      </SectionHeader>

      <SectionContent>
        <ListElement>
          <ListItem>
            <IPhoneAppImg />
          </ListItem>
          <ListItem>
            <AndroidImg />
          </ListItem>
          <ListItem>
            <AmazonFireImg />
          </ListItem>
          <ListItem>
            <AppleTVImg />
          </ListItem>
          <ListItem>
            <MoreImg />
          </ListItem>
        </ListElement>
        <SectionCTA>
          <ButtonWrapper>
            <a href={formattedCTAQuery}>{buttonCopy}</a>
          </ButtonWrapper>
          <p>{ctaSubtext}</p>
        </SectionCTA>
      </SectionContent>
    </SectionElement>
  )
}

export default PlatformSection

const SectionElement = styled.section`
  text-align: center;
  background: #fafafa;
`

const SectionHeader = styled.div`
  background-color: #242526;
  padding: 65px 3% 65px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  background: no-repeat 50% 30px url(${headerBgImg});
  background-color: #242526;

  h1 {
    color: #fff;
    font-weight: 700;
    font-family: 'Qanelas-Bold', sans-serif;
    line-height: 31.6px;
    margin-bottom: 5px;
    font-size: 24.32px;
  }

  h2 {
    color: rgba(255, 255, 255, 0.7);
    line-height: 33.6px;
    font-weight: 400;
    font-size: 22px;
  }
`

const SectionContent = styled.div`
  max-width: 1000px;
  margin: -20px auto 0;
  // padding: 0 15%;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 736px) and (min-width: 320px) {
    padding: 0 10%;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`

const ListElement = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2%;
  margin-top: -30px;

  @media screen and (min-width: 320px) and (max-width: 736px) {
    flex-direction: column nowrap;
  }
`

const ListItem = styled.li`
  width: 30%;
  margin-right: 10px;
  width: 100%;
  max-width: 276px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  position: relative;
  margin: 0 12px 12px 0;

  // :nth-last-child(-n+3) {
  //     margin-top: 20px;
  // }

  @media screen and (min-width: 320px) and (max-width: 736px) {
    flex: 33%;
    width: 150px;
    margin-bottom: 6px;
    margin-right: 6px;

    :nth-last-child(-n + 3) {
      margin-top: 0;
    }
  }

  @media (max-width: 600px) {
    max-width: 30%;
  }

  img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px auto;
  margin-bottom: 10px;
  font-family: 'Qanelas', sans-serif;
  font-size: 23px;

  a {
    display: inline-block;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    background: #fa4616;
    padding: 18px 20px;
    border-radius: 3px;
    font-weight: 700;
    font-family: 'Qanelas-Bold', sans-serif;
    text-align: center;
    width: 320px;

    text-transform: uppercase;

    :hover {
      background: #b33922;
      cursor: pointer;
    }

    @media screen and (max-width: 736px) and (min-width: 320px) {
      font-size: 20px;
      width: 242px;
    }
  }
`

const ButtonElement = styled.button`
  position: relative;
  font-size: 1.5em;
  background: #fa4616;
  padding: 18px 20px;
  border-radius: 5px;
  font-weight: 700;
  width: 320px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 30px auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;

  @media screen and (max-width: 736px) and (min-width: 320px) {
    width: 300px;
  }
  :hover {
    background: #b33922;
    cursor: pointer;
  }

  a {
    font-size: 23px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 736px) and (min-width: 320px) {
      font-size: 20px;
    }
  }
`

const SectionCTA = styled.div`
  color: rgb(64, 64, 64);
  padding-bottom: 15px;

  button {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 14px;
  }
`
