import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_hawks.videoUrl}
    staticAnnotation={videos.bally_hawks.staticAnnotation}
    title="Watch Atlanta basketball live"
    subtitle="STREAM THE HAWKS ON BALLY SPORTS SOUTHEAST"
    customNetworks={['ballysportssoutheast']}
  />
)
