import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/Wimbledon24_LP_7s.mp4"
    staticAnnotation={networkLogos.espn}
    title="Watch Wimbledon"
    subtitle="SEE THE SUPERSTARS OF TENNIS COMPETE LIVE"
    tagline="Stream matches July 1-14 from any device"
    networkSet="sports"
    ctaText={'Start Watching'}
  />
)
