import React, { useState } from 'react'
import BundleSection from 'src/components/common/BundleSection'
import { cardData, offerSlug } from 'src/components/common/BundlePlansLatino/constants'

import BundleCard from 'src/components/common/BundleCard'
import LigaImg1x from 'src/assets/images/latino_liga_1x.png'
import LigaImg2x from 'src/assets/images/latino_liga_2x.png'
import FutbolImg1x from 'src/assets/images/conmebol/latinocard_v3_1x.png'
import FutbolImg2x from 'src/assets/images/conmebol/latinocard_v3_2x.png'
import {
  defaultCTAURI,
  LatinoDefaultCtaTextShort,
  LatinoESDefaultCtaText,
} from 'src/modules/utils/constants'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'

const BundlePlansLatinoQuarterlyOnly = ({ ctaQuery, ctaText, lang = 'en' }) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  let copy = cardData[lang]

  const ctaCopy = ctaText || (lang === 'es' ? LatinoESDefaultCtaText : LatinoDefaultCtaTextShort)

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'card')
  // ctaURL = replaceUrlParam(ctaURL, 'disableQuarterlyUpsell', 'true')

  return (
    <BundleSection
      title={
        lang === 'es'
          ? 'Lo Mejor de la TV y el Deporte en Español'
          : 'The best of live TV and sports in Spanish'
      }
      subTitle={
        lang === 'es'
          ? 'Obtén el plan con el mejor precio y los mejores canales en vivo.'
          : 'Get the plan with the best price, top channels and no tricks.'
      }
      hasFeatured={true}
      id="select-plan"
    >
      <BundleCard
        eyebrowHeadline={copy.name['monthly']}
        isFeatured
        featureText="Con Seis disfruta de esta oferta durante 3 meses"
        headlineDescription={copy.headlineDescription}
        priceHeader={copy.priceHeader['monthly']}
        priceDiscount="$22.99/mes*"
        listItems={copy.listItems}
        ctaUrl={ctaURL}
        ctaText={ctaCopy}
        promoImg={[
          [LigaImg1x, LigaImg2x],
          [FutbolImg1x, FutbolImg2x],
        ]}
        contentWidth="330px"
        imageHeight="50%"
        footnoteOutside="*Se renueva a $32.99/mes después del los tres primeros meses. Pueden aplicarse impuestos y otros cargos. Cancela cuando quieras."
      />
    </BundleSection>
  )
}

export default BundlePlansLatinoQuarterlyOnly
