import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.yes_network.videoUrl}
    title="Watch YES Network on Fubo"
    subtitle="STREAM LIVE GAMES AND LOCAL TEAM COVERAGE"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="sports"
    customNetworks={['yesnetwork', 'msg', 'mlbnetwork']}
    league="MLB"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/logos/logo-fubo-yes.png?w=451&ch=width&auto=format,compress"
    logoHeight="56px"
  />
)
