import React from 'react'
import styled from 'styled-components'

import { DefaultCtaTextShort, defaultCTAURI } from 'src/modules/utils/constants'
import Snowflake from './Snowflake'
import Snow from './Snow'
import Slope from './Slope'
import CTAButton from 'src/components/common/CTAButton'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'

const HolidayTv = ({ ctaQuery, ctaText, tvImage, headline, subheadline, miceText }) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'holidaybanner')

  return (
    <SectionElement>
      <div className="SectionInner">
        <div className="SectionImage">
          <img src={tvImage} />

          {/* Snowflakes */}
          {/* <div className="snowflake snowflake-4">
            <Snowflake />
          </div>
          <div className="snowflake snowflake-5">
            <Snowflake />
          </div>
          <div className="snowflake snow-dark snow-dark-3">
            <Snow />
          </div>
          <div className="snowflake snow-dark snow-dark-4">
            <Snow />
          </div>

          <div className="snowflake snow-light snow-light-3">
            <Snow />
          </div> */}
        </div>
        <div className="SectionCopy">
          <h1>{headline}</h1>
          <h2>{subheadline}</h2>
          <div className="ButtonElement">
            <CTAButton link={ctaURL}>{ctaText || DefaultCtaTextShort}</CTAButton>
          </div>
          {miceText && <div className="mice-text">{miceText}</div>}

          {/* Snowflakes */}
          {/* <div className="snowflake snowflake-1">
            <Snowflake />
          </div>
          <div className="snowflake snowflake-2">
            <Snowflake />
          </div>
          <div className="snowflake snowflake-3">
            <Snowflake />
          </div>
          <div className="snowflake snow-dark snow-dark-1">
            <Snow />
          </div>
          <div className="snowflake snow-light snow-light-1">
            <Snow />
          </div>
          <div className="snowflake snow-light snow-light-2">
            <Snow />
          </div>
          <div className="snowflake snow-dark snow-dark-2">
            <Snow />
          </div> */}
        </div>
      </div>
      {/* <div className="snowslope">
        <Slope />
      </div> */}
    </SectionElement>
  )
}

export default HolidayTv

const SectionElement = styled.section`
  ${'' /* background-color: #fff; */}
  background-image: url("https://assets-imgx.fubo.tv/marketing/lp/images/holidays/2000x1080_holiday23_bg.jpg?ch=width&auto=format,compress");
  ${'' /* background-position: center; */}
  background-repeat: repeat-x;
  background-size: auto 100%;
  ${'' /* background: linear-gradient(140.31deg, #fa4616 14.64%, #721e4e 117.85%); */}
  position: relative;
  overflow: hidden;
  padding: 20px 30px 40px;
  @media screen and (max-width: 767px) {
    padding: 30px 30px;
  }
  .snowflake {
    position: absolute;
    svg {
      width: 100%;
    }
  }
  .snowflake-1 {
    top: -24px;
    right: -70px;
    width: 45px;
  }
  .snowflake-2 {
    bottom: -58px;
    right: -40px;
    width: 41px;
    @media screen and (max-width: 1000px) {
      bottom: -28px;
      right: 0;
    }
    @media screen and (max-width: 736px) {
      right: -25px;
    }
  }
  .snowflake-3 {
    top: 50%;
    left: -60px;
    width: 36px;
    @media screen and (max-width: 1000px) {
      left: -50px;
    }
  }
  .snowflake-4 {
    top: -12px;
    left: 50%;
    width: 28px;
    transform: rotate(20deg);
  }
  .snowflake-5 {
    top: 0;
    left: -10px;
    width: 48px;
  }

  .snow-dark-1 {
    top: -70px;
    right: 11%;
    width: 6px;
    mix-blend-mode: darken;
    @media screen and (max-width: 736px) {
      right: 7%;
    }
  }
  .snow-dark-2 {
    top: -30px;
    left: -32px;
    width: 8px;
    @media screen and (max-width: 736px) {
      left: 0px;
    }
  }
  .snow-dark-3 {
    top: -28px;
    right: 32%;
    width: 6px;
  }
  .snow-dark-4 {
    top: 26%;
    left: -21px;
    width: 6px;
  }

  .snow-light {
    opacity: 0.5;
  }
  .snow-light-1 {
    top: -48px;
    right: 26%;
    width: 15px;
    @media screen and (max-width: 1000px) {
      top: -24px;
    }
    @media screen and (max-width: 736px) {
      right: 0;
      top: -160px;
    }
  }
  .snow-light-2 {
    top: 55%;
    right: -32px;
    width: 8px;
  }
  .snow-light-3 {
    top: -12px;
    left: 27%;
    width: 11px;
  }

  .snowslope {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -10px;
    width: 80%;
    @media screen and (max-width: 736px) {
      display: none;
    }
    svg {
      width: 100%;
    }
  }

  .SectionInner {
    z-index: 3;
    position: relative;
    max-width: 1000px;
    ${'' /* padding: 20px 30px; */}
    margin: 0 auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${'' /* @media screen and (max-width: 767px) {
      padding: 30px 30px;
    } */}
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
  .SectionCopy {
    position: relative;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
      width: 50%;
      max-width: 460px;
      flex: 1 0 460px;
    }

    @media screen and (min-width: 1000px) {
      width: 60%;
      max-width: 500px;
      flex: 1 0 500px;
    }

    h1 {
      ${'' /* color: #242526; */}
      color: #fff;
      font-weight: 700;
      font-family: Qanelas-ExtraBold, sans-serif;
      font-size: 42px;
      line-height: 1.2;
      margin-bottom: 5px;

      @media screen and (min-width: 768px) {
        font-size: 38px;
      }

      @media screen and (min-width: 1000px) {
        font-size: 42px;
      }
    }

    h2 {
      ${'' /* color: #242526; */}
      color: #fff;
      font-family: Qanelas-Medium, sans-serif;
      font-size: 23px;
      line-height: 1.4;
      font-weight: 500;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        font-size: 22px;
        margin-bottom: 30px;
      }

      @media screen and (min-width: 1000px) {
        font-size: 24px;
        margin-bottom: 40px;
      }
    }
  }

  .mice-text {
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    opacity: 0.9;
    max-width: 800px;
    margin: 20px auto 0;
    @media (min-width: 768px) {
      margin-top: 30px;
      text-align: left;
    }
  }

  .SectionImage {
    ${'' /* display: flex;
    align-items: center;
    justify-content: center; */}
    position: relative;
    @media screen and (min-width: 768px) {
      flex: 0 1 400px;
    }
    img {
      width: 100%;
      max-width: 400px;
    }
  }

  .ButtonElement {
    position: relative;
    width: 100%;
    margin: 10px auto;
    font-family: 'Qanelas', sans-serif;
    .CTAButton {
      background: #fff;
      color: #000;
      padding: 14px 24px;
      width: 280px;
      font-weight: 700;
      font-family: 'Qanelas', sans-serif;
      text-shadow: none;
      font-size: 23px;
      transition: background 0.5s, border-radius 0.5s, color 0.5s;
      &:hover {
        background: #d7d7d7;
      }
      @media screen and (max-width: 1200px) {
        width: auto;
        font-size: 21px;
        padding: 16px 30px;
      }
      @media screen and (max-width: 736px) {
        width: 242px;
        font-size: 20px;
        padding: 10px 15px;
      }
    }
  }
`
