import React, { useEffect, useState } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        // noVideo={true}
        poster={
          'https://assets-imgx.fubo.tv/marketing/lp/images/posters/us_logosoup_2.jpg?w=1920&ch=width&auto=format,compress'
        }
        title="Stream live TV & sports"
        subtitle="Watch and DVR the channels you love."
        showRSN={true}
        newTemplate={true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .hero-content {
    h1 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
    h2 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
  }
`
