import { emitter } from '@marvelapp/react-ab-test'
import React, { useEffect, useState } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { useABTest } from 'src/modules/hooks/useABTest'

const abName = 'General_Football_Video_v_Static'
let definedVariants = [
  abName + '-video-generic-football',
  abName + '-wide-field',
  abName + '-football-catch',
  abName + '-football-talent',
]

emitter.defineVariants(abName, definedVariants)
emitter.calculateActiveVariant(abName)
let variants = {
  [definedVariants[0]]: definedVariants[0],
  [definedVariants[1]]: definedVariants[1],
  [definedVariants[2]]: definedVariants[2],
  [definedVariants[3]]: definedVariants[3],
}

export default props => {
  const [testVariant, setTestVariant] = useState(null)
  const variant = useABTest(abName, variants)
  useEffect(() => {
    if (typeof variant !== 'undefined' && variant !== null) {
      setTestVariant(variant)
    }
  }, [variant])

  if (testVariant == definedVariants[1]) {
    //-wide-field
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title="Watch football live without cable"
        subtitle="CBS, ESPN, FOX, NFL Network"
        tagline="Stream pro and college football live online. No contract or commitment."
        networkSet="football"
        showRSN={true}
        noVideo
        staticAnnotation={null}
        annotations={null}
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-HORZ-V1.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-VERT-V1.jpg?ch=width&auto=format,compress'
        }
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[2]) {
    //-football-catch
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title="Watch football live without cable"
        subtitle="CBS, ESPN, FOX, NFL Network"
        tagline="Stream pro and college football live online. No contract or commitment."
        networkSet="football"
        showRSN={true}
        noVideo
        staticAnnotation={null}
        annotations={null}
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-HORZ-V2.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-VERT-V2.jpg?ch=width&auto=format,compress'
        }
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[3]) {
    //-football-talent
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title="Watch football live without cable"
        subtitle="CBS, ESPN, FOX, NFL Network"
        tagline="Stream pro and college football live online. No contract or commitment."
        networkSet="football"
        showRSN={true}
        staticAnnotation={networkLogos.fox}
        noVideo
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-TALENT-STATIC-HORZ.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/NFL-TALENT-STATIC-VERT.jpg?ch=width&auto=format,compress'
        }
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[0]) {
    // CONTROL
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.generic_football.videoUrl}
        title="Watch football live without cable"
        subtitle="CBS, ESPN, FOX, NFL Network"
        tagline="Stream pro and college football live online. No contract or commitment."
        networkSet="football"
        showRedzone={true}
      />
    )
  } else {
    return null
  }
}
