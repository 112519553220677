import React from "react"
import BallySportsMLB from "src/components/page/bally-sports-mlb"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_angels.videoUrl}
    staticAnnotation={videos.bally_angels.staticAnnotation}
    title="Watch Los Angeles baseball live"
    subtitle="STREAM THE ANGELS ON BALLY SPORTS WEST"
    customNetworks={["ballysportswest"]}
  />
)