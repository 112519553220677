import { emitter } from '@marvelapp/react-ab-test'
import React, { useEffect, useState } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { useABTest } from 'src/modules/hooks/useABTest'

const abName = 'College_Football_Video_v_Static'
let definedVariants = [
  abName + '-video-game-footage',
  abName + '-football-catch',
  abName + '-player',
]

emitter.defineVariants(abName, definedVariants)
emitter.calculateActiveVariant(abName)
let variants = {
  [definedVariants[0]]: definedVariants[0],
  [definedVariants[1]]: definedVariants[1],
  [definedVariants[2]]: definedVariants[2],
}

export default props => {
  const { title, subtitle, hasGenericVideo } = props
  const [testVariant, setTestVariant] = useState(null)
  const variant = useABTest(abName, variants)

  let teamSubtitle

  if (props.team) {
    teamSubtitle = `SEE THE ${props.team.toUpperCase()} IN ACTION`
  }

  useEffect(() => {
    if (typeof variant !== 'undefined' && variant !== null) {
      setTestVariant(variant)
    }
  }, [variant])

  if (testVariant == definedVariants[1]) {
    // football-catch
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title={title || 'Watch college football live without cable'}
        subtitle={subtitle || teamSubtitle || 'Stream ACC, SEC, ESPN and more live online'}
        tagline={
          props.team
            ? 'Stream live games from any device'
            : 'Fubo has the most college football you can get without cable'
        }
        networkSet="collegeFootball"
        // teams
        team={props.team}
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/CFB-PLAYER-STATIC-HORZ-V1.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/CFB-PLAYER-STATIC-VERT-V1.jpg?ch=width&auto=format,compress'
        }
        noVideo
        matchtickerSportId="111"
        matchtickerLeagueName="College Football"
        matchtickerLeagueId="191261"
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[2]) {
    //-player
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        title={title || 'Watch college football live without cable'}
        subtitle={subtitle || teamSubtitle || 'Stream ACC, SEC, ESPN and more live online'}
        tagline={
          props.team
            ? 'Stream live games from any device'
            : 'Fubo has the most college football you can get without cable'
        }
        networkSet="collegeFootball"
        // teams
        team={props.team}
        noVideo
        poster={
          'https://assets.fubo.tv/marketing/lp/images/posters/CFB-PLAYER-STATIC-HORZ-V2.jpg?ch=width&auto=format,compress'
        }
        posterMobile={
          'https://assets.fubo.tv/marketing/lp/images/posters/CFB-PLAYER-STATIC-VERT-V2.jpg?ch=width&auto=format,compress'
        }
        matchtickerSportId="111"
        matchtickerLeagueName="College Football"
        matchtickerLeagueId="191261"
        showRedzone={true}
      />
    )
  } else if (testVariant == definedVariants[0]) {
    //CONTROL
    return (
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={hasGenericVideo ? videos.generic_football.videoUrl : videos.cfb.videoUrl}
        title={title || 'Watch college football live without cable'}
        subtitle={subtitle || teamSubtitle || 'Stream ACC, SEC, ESPN and more live online'}
        tagline={
          props.team
            ? 'Stream live games from any device'
            : 'Fubo has the most college football you can get without cable'
        }
        networkSet="collegeFootball"
        // teams
        team={props.team}
        matchtickerSportId="111"
        matchtickerLeagueName="College Football"
        matchtickerLeagueId="191261"
        showRedzone={true}
      />
    )
  } else {
    return null
  }
}
