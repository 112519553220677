import React from "react"
import BallySportsNHL from "src/components/page/bally-sports-nhl"
import { videos } from "src/constants/videos"
export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_wild.videoUrl}
    staticAnnotation={videos.bally_wild.staticAnnotation}
    title="Watch Minnesota hockey live"
    subtitle="STREAM THE WILD ON BALLY SPORTS NORTH"
    customNetworks={["ballysportsnorth"]}
  />
)