import React from "react"

const Snow = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2729 7.77292C16.2729 12.0658 12.7929 15.5458 8.5 15.5458C4.20714 15.5458 0.727083 12.0658 0.727083 7.77292C0.727083 3.48005 4.20714 0 8.5 0C12.7929 0 16.2729 3.48005 16.2729 7.77292Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}

export default Snow
