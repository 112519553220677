import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_clippers.videoUrl}
    staticAnnotation={videos.bally_clippers.staticAnnotation}
    title="Watch Los Angeles basketball live"
    subtitle="STREAM THE CLIPPERS ON BALLY SPORTS SOCAL"
    customNetworks={['ballysportssocal']}
  />
)
