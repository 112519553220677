import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'


export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.uefa_euros.videoUrl}
    title="Watch the UEFA Euro 2024"
    subtitle="FUBO IS THE ONLY WAY TO WATCH EVERY MATCH"
    tagline="Stream them all live from your TV & other devices"
    networkSet={"soccer"}
    customNetworks={['fsn','fox', 'fs1', 'espn', 'bein', 'tudn', 'abc']}
  />
)
