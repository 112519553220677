import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_padres.videoUrl}
    title="Watch San Diego baseball live"
    subtitle="STREAM PADRES GAMES ALL SEASON LONG"
  />
)
