import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import Slider from 'react-slick'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import { useSelector } from 'react-redux'
import { track } from 'src/modules/analytics/track'
import CarouselItem from 'src/components/common/ScheduleBannerV2/CarouselItem'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'

const ScheduleBanner = ({ lang = 'en', items, title, subtitle, link = defaultCTAURI, ctaText }) => {
  const analyticsProperties = useSelector(selectAnalyticsProperties)
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(link, parsedParams)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'matchticker')

  const handleOnClickPrev = e => {
    const eventProperties = {
      ...analyticsProperties,
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'schedule_banner_prev',
      },
    }
    track('lp_interaction', eventProperties)
  }

  const handleOnClickNext = e => {
    const eventProperties = {
      ...analyticsProperties,
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'schedule_banner_next',
      },
    }
    track('lp_interaction', eventProperties)
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props
    const handleOnClick = () => {
      onClick && onClick()
      handleOnClickPrev()
    }
    return (
      <div className={className} style={style} onClick={handleOnClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="44"
          viewBox="0 0 26 44"
          fill="none"
        >
          <path
            transform="scale(-1, 1) translate(-26, 0)"
            d="M0.951909 5.2839C-0.317297 4.07514 -0.317297 2.11534 0.951908 0.906576C2.22111 -0.302189 4.2789 -0.302189 5.5481 0.906576L25.0481 19.478C26.3173 20.6868 26.3173 22.6466 25.0481 23.8553L5.5481 42.4268C4.2789 43.6355 2.22112 43.6355 0.951912 42.4268C-0.317293 41.218 -0.317294 39.2582 0.951911 38.0494L18.1538 21.6667L0.951909 5.2839Z"
            fill="white"
          />
        </svg>
      </div>
    )
  }

  function NextArrow(props) {
    const { className, style, onClick } = props
    const handleOnClick = () => {
      onClick && onClick()
      handleOnClickNext()
    }
    return (
      <div className={className} style={style} onClick={handleOnClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="44"
          viewBox="0 0 26 44"
          fill="none"
        >
          <path
            d="M0.951909 5.2839C-0.317297 4.07514 -0.317297 2.11534 0.951908 0.906576C2.22111 -0.302189 4.2789 -0.302189 5.5481 0.906576L25.0481 19.478C26.3173 20.6868 26.3173 22.6466 25.0481 23.8553L5.5481 42.4268C4.2789 43.6355 2.22112 43.6355 0.951912 42.4268C-0.317293 41.218 -0.317294 39.2582 0.951911 38.0494L18.1538 21.6667L0.951909 5.2839Z"
            fill="white"
          />
        </svg>
      </div>
    )
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  return (
    <StyledScheduleBanner>
      {/* <h2>{title}</h2> */}
      <h3>{title}</h3>
      <Slider {...settings} className="carousel-container">
        {items?.map((item, index) => {
          return (
            <CarouselItem
              key={index}
              lang={lang}
              item={item}
              link={ctaURL}
              index={index}
              ctaText={ctaText}
            />
          )
        })}
      </Slider>
    </StyledScheduleBanner>
  )
}

export default ScheduleBanner

const StyledScheduleBanner = styled.div`
  padding: 20px 30px 20px;
  ${'' /* max-width: 1200px; */}
  margin: 0 auto;
  --side-padding: 100px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px 4px 10px;
    --side-padding: 40px;
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    font-size: 40px;
    ${'' /* padding: 0 var(--side-padding); */}
    ${'' /* margin-bottom: 20px; */}
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    ${'' /* line-height: 1.2; */}
    ${'' /* padding: 0 var(--side-padding); */}
    ${'' /* margin-bottom: 16px; */}
    flex: 0 0 auto;
    @media (max-width: 768px) {
      font-size: 20px;
      padding: 10px 10px;
    }
  }

  .slick-list {
    ${'' /* padding: 0 calc(var(--side-padding) - 10px); */}
    padding: 0 30px;
  }

  .carousel-container {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: stretch;
    flex: 0 1 auto;
    width: 100%;
  }

  .slick-track {
    height: 100%;
    ${'' /* padding: 0 30px; */}
    ${'' /* left: 30px; */}
  }

  .slick-slide {
    height: 100%;
    padding: 0 10px;
    @media (max-width: 767px) {
      padding: 0 4px;
    }
  }

  .slick-slide > div {
    height: 100%;
  }
  .slick-arrow {
    ${'' /* width: 50px; */}
    ${'' /* position: relative; */}
    padding: 0 10px;
    position: absolute;
    height: 100%;
    ${'' /* background: #1d1f29; */}
    color: #fff;
    ${'' /* font-size: 50px; */}
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 5;

    ${'' /* flex-grow: 0;
    flex-shrink: 0; */}

    ::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
    }

    svg {
      z-index: 5;
      width: 16px;
      ${'' /* height: 20px; */}
      @media (max-width: 768px) {
      width: 12px;
    }
    }

    &:hover {
      ${'' /* background-color: rgba(0, 0, 0, 0.7); */}
    }

    @media (max-width: 768px) {
      width: 20px;
    }
  }

  .slick-prev {
    left: 0px;
    ${'' /* padding-right: 60px; */}
    ::before {
      background: linear-gradient(90deg, #17181f 39.52%, rgba(18, 19, 27, 0) 100%);
      @media (max-width: 768px) {
      }
    }
  }

  .slick-next {
    right: 0px;
    ${'' /* padding-left: 60px; */}
    ::before {
      background: linear-gradient(-90deg, #17181f 39.52%, rgba(18, 19, 27, 0) 100%);
      width: 500%;
      @media (max-width: 768px) {
      }
    }
  }
`
