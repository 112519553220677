import React from 'react'
import Layout from 'src/components/common/Layout'
import styled from 'styled-components'

import { defaultCTAURI } from 'src/modules/utils/constants'
import CTAButton from 'src/components/common/CTAButton'
import { track } from 'src/modules/analytics/track'
import { createUrl } from 'src/modules/utils/createUrl'
import { selectCTAParams } from 'src/modules/reducers/params'
import { useSelector } from 'react-redux'

const BestBuyTemplate = ({ lang = 'en', market = 'all' }) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  const handleOnClick = e => {
    const eventProperties = {
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'cta_platform_section',
        content_name: 'ACTIVATE YOUR PURCHASE',
      },
    }
    track('lp_interaction', eventProperties)
  }

  return (
    <Layout lang={lang} market={market}>
      <StyledBestBuyTemplate>
        {/* <SEO title={pageTitle} description={pageDescription} meta={pageMeta} /> */}
        <div className="best-buy-image">
          <img src="https://assets-imgx.fubo.tv/marketing/lp/images/partners/fubotv_bestbuy_lockup.png?w=450&ch=width&auto=format,compress" />
        </div>
        <div className="content-card">
          <h1>Start watching live TV with Fubo</h1>
          <CTAButton link={ctaURL} onClick={handleOnClick}>
            ACTIVATE YOUR PURCHASE
          </CTAButton>
        </div>
      </StyledBestBuyTemplate>
    </Layout>
  )
}

export default BestBuyTemplate

const StyledBestBuyTemplate = styled.div`
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-color: #15101e;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .best-buy-image {
    text-align: center;
    img {
      height: 70px;
      width: 300px;
      margin: 0 auto 40px auto;
    }
  }

  .content-card {
    background: rgba(255, 255, 255, 0.95);
    padding: 80px 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 95%;
    border-radius: 15px;
    gap: 30px;
    margin: 0 40px 200px;

    @media screen and (max-width: 1000px) {
      padding: 80px 6%;
    }

    @media screen and (max-width: 767px) {
      margin: 0 10px 100px;
      padding: 80px 6%;
    }

    h1 {
      font-size: 42px;
      line-height: 1.25;
      margin-bottom: 20px;
      color: #1a1a1a;
      letter-spacing: -1px;
      font-family: 'Qanelas Extra Bold', sans-serif, Arial;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: block;
      @media screen and (max-width: 767px) {
        font-size: 34px;
      }
    }
    .cta-button {
      font-size: 22px;
      background: #fa4616;
      padding: 18px 20px;
      width: 420px;
      border-radius: 3px;
      font-weight: 700;
      ${'' /* box-shadow: 0px 0px 5px rgb(0 0 0 / 80%); */}
      text-align: center;
      margin: 0 auto;
      max-width: 100%;
      box-sizing: border-box;
      text-decoration: none;
      color: #fff;
      ${'' /* text-shadow: 0px 0px 3px rgb(0 0 0 / 30%); */}
      font-family: "Qanelas Extra Bold", sans-serif, Arial;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }

      &:hover {
        background: #b33922;
      }
    }
  }
`
